<template>
  <div :id="elemId" style="height: 100%, width: 100%">
    <b-modal v-model="selectorShow" size="xl" :title="selectorTitleLabel" footer-class="footerClass"
      no-close-on-backdrop 
      :no-close-on-esc="isCellEditing || editorShow" :modal-class="[elemId]"
      content-class="shadow"  @ok="ok" @hidden="hidden">
      
      <AlertFeedback v-if="alertObj.msg != null" 
        :msg="alertObj.msg" 
        :details="alertObj.msgDetails.list" 
        :detailTitle="alertObj.msgDetails.title" 
        :alertState="alertObj.state" 
        @resetAlert="resetAlert"
      />
      
      <div v-if="status !== null && status !== 'not migrated'">
        <span v-if="status !== 'migrated'">{{ $t('sandbox.publishing') }}</span>
        <b-progress v-if="status !== 'migrated'" :max="100" height="2rem">
          <b-progress-bar :value="statusPercentage" :label="`${statusPercentage.toFixed(0)}%`"/>
        </b-progress>
      </div>
      
      <ag-grid-vue style="width: 100%" class="ag-theme-balham generic-selector selector-grid-height spreadsheet" id="rebate-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :autoGroupColumnDef="autoGroupColumnDef"
            :columnDefs="columnDefs"
            :getMainMenuItems="getMainMenuItems"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => typeof params.data.uuId !== 'undefined' ? `${params.data.uuId}${params.data.treepath}` : params.data.name"
            :rowMultiSelectWithClick="nonAdmin"
            :rowSelection="singleSelection? 'single' : 'multiple'"
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressContextMenu
            suppressDragLeaveHidesColumns
            :singleClickEdit="false"
            :suppressMultiSort="false"
            suppressGroupRowsSticky
            suppressClipboardApi
            :getDataPath="data => data.treepath.split(', ')"
            :rowData="rowData"
            treeData
            groupDefaultExpanded="-1"
            :suppressRowClickSelection="!nonAdmin"
            suppressCellFocus
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"

            :overlayLoadingTemplate="overlayLoadingTemplate"
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ /*ok, */cancel }">
        <b-form-checkbox class="apply-to-all" v-model="testMode" @change="initColumns">{{ $t('test_mode') }}</b-form-checkbox>
        <b-button :disabled="disableOk" size="sm" variant="success" @click="publish(true)">{{ $t('button.publish') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>

    <AuditModal v-if="allowManage && editorShow" 
      :id="entityId" 
      :parent="entityParent"
      :show.sync="editorShow" 
      @success="editorSuccess" 
      :labelTitle="editorTitleLabel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>

    <b-modal :title="$t('entity_selector.confirm_deletion_title')"
        v-model="ecConfirmDeleteShow"
        @hidden="ecConfirmDeleteShow=false"
        @ok="ecConfirmDeleteOk"
        @cancel="ecConfirmDeleteCancel"
        content-class="entity-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ ecConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="ecShowApplyAllCheckbox" class="apply-to-all" v-model="entityCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('sandbox.publish_error')"
        v-model="showErrorJob"
        @hidden="showErrorJob=false"
        content-class="entity-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ errorJob }}</p>
      
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('sandbox.warn_published_title')"
        v-model="warnPublished"
        @ok="confirmPublishedOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('sandbox.warn_published') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import 'ag-grid-enterprise'
import { AgGridVue } from 'ag-grid-vue'

import alertStateEnum from '@/enums/alert-state'
import { cloneDeep, debounce } from 'lodash'
import { strRandom, objectClone, invertColor, randomString, isEllipsisActive,
         getStaffEmailInUse
 } from '@/helpers'
 import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, columnDefSortFunc } from '@/views/management/script/common'
import { getPermissionDenyProperties, hasPermission } from '@/helpers/permission'
import { fieldValidateUtil } from '@/script/helper-field-validate'
import { getCustomFieldInfo, prepareCustomFieldColumnDef, handleCustomFieldError, getCustomFieldExportDataPropertyHandler } from '@/helpers/custom-fields'

import { viewProfileService, tagService, queryService, sandboxProfileService,
  departmentService, customerService, locationService, projectService, staffService
  , templateProjectService, skillService, resourceService, rebateService
, compositeService, companyService, sandboxService, profileService } from '@/services'
import { getComparator } from './script/columnComparator';
import { getKeysWithoutRedactedFields } from '@/services/common';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink'
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color'
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/SelectorRowSelector'
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum'
import GenericEntityArrayCellRenderer from '@/components/Aggrid/CellRenderer/GenericEntityArray'
import StaffCountCellRenderer from '@/components/Aggrid/CellRenderer/StaffCount'
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic'
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly'
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean'
import ErrorDetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/ErrorDetailLink'
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link'

import NameEditor from '@/components/Aggrid/CellEditor/Name'
import StringEditor from '@/components/Aggrid/CellEditor/String'
import TagEditor from '@/components/Aggrid/CellEditor/Tag'
import ColorEditor from '@/components/Aggrid/CellEditor/Color'
import ListEditor from '@/components/Aggrid/CellEditor/List'
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime'
import FloatNumericEditor from '@/components/Aggrid/CellEditor/FloatNumeric'
import IntegerNumericEditor from '@/components/Aggrid/CellEditor/IntegerNumeric'
import SelectionHeaderComponent from '@/components/Aggrid/CellHeader/Selection';

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';


const operationStatus = {
  SUCCESS: 'SUCCESS'
  , ABORT: 'ABORT'
}
Object.freeze(operationStatus)
const operationStatusKeys = Object.keys(operationStatus)

const CELL_COPY_CODE = '_COPIED_OBJ='

function sortFunc(a, b) {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

const UUID_MATCH = /.*([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}).*/

let migrateStatus = {};

let migrateStatusTest = {};

export default {
  name: 'AuditSelectorModalForAdmin'
  // , emits: ['ok', 'cancel']
  , components: {
    'ag-grid-vue': AgGridVue
    , AlertFeedback: () => import('@/components/AlertFeedback')
    , InProgressModal: () => import('@/components/modal/InProgressModal')
    , AuditModal: () => import('@/components/modal/AuditModal')

    /* eslint-disable vue/no-unused-components */
    //Renderer
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , colorCellRenderer: ColorCellRenderer
    , rowSelectorCellRenderer: RowSelectorCellRenderer
    , enumCellRenderer: EnumCellRenderer
    , genericEntityArrayCellRenderer: GenericEntityArrayCellRenderer
    , staffCountCellRenderer: StaffCountCellRenderer
    , genericCellRenderer: GenericCellRenderer
    , dateOnlyCellRenderer: DateOnlyCellRenderer
    , booleanCellRenderer: BooleanCellRenderer
    , 'selectionHeaderComponent': SelectionHeaderComponent
    , 'errorDetailLinkCellRenderer': ErrorDetailLinkCellRenderer
    , 'linkCellRenderer': LinkCellRenderer
    
    //Editor
    , nameEditor: NameEditor
    , stringEditor: StringEditor
    , tagEditor: TagEditor
    , colorEditor: ColorEditor
    , listEditor: ListEditor
    , dateTimeEditor: DateTimeEditor
    , floatNumericEditor: FloatNumericEditor
    , integerNumericEditor: IntegerNumericEditor

    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    , hideStaffCount: {
      type: Boolean
      , default: false
    }
    , company: {
      type: Object
      , default: null
    }
    , disableCompanySelection: {
      type: Boolean
      , default: false
    }
    , exclude: {
      type: String,
      default: null
    }
    //----label----
    , selectorTitle: {
      type: String
      , default: null
    }
    /** Non admin related props **/
    , nonAdmin: {
      type: Boolean,
      default: false
    }
    , preselected: {
      type: [String, Array],
      default: null
    }
    , singleSelection: {
      type: Boolean,
      default: false
    }
    , noSubCompany: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      elemId: 'DEPARTMENT_SELECTOR_FOR_ADMIN'
      , permissionName: 'DEPARTMENT'
      , allowManage: true //always true. It is referenced in  DetailLink.vue

      , alertObj: {
        msg: null
        , msgDetails: { title: null, list: [] }
        , state: alertStateEnum.SUCCESS
      }
      , gridOptions: null
      , gridApi: null

      , selectorShow: false
      , searchFilter: ''
      
      , filterText: ''
      , filterTextFocus: false
      , closePriorityNavDropdown: false

      , editorShow: false
      , entityId: null
      , entityParent: null
      , coloring: {}

      , duplicateShow: false
      , duplicateName: null
      , duplicateFirstname: null
      , duplicateLastname: null
      , duplicateEmail: null
      , duplicateInProgress: false
      
      , docImportShow : false
      , docImportProperties : []
      , entityMap: {}

      , inProgressShow: false
      , inProgressLabel: null
      , inProgressStoppable: false
      , inProgressState: {
        cancel: false
      }

      , rowSelectorClicked_allColsSelectedRowIndexList: []
      , rangeSelection: []
      , entitySelection: []

      , pendingListByFillOperation: []
      , triggeredByFillOperation: false
      , processValueChangedList: []
      , processDateValueChangedList: []
      , pendingProcessRequestList: []
      , pendingRequestBatchList: []
      , pendingDeleteCells: []

      , ecConfirmDeleteShow: false
      , ecConfirmDeleteEntities: []
      , entityCol: {
        entityName: null
        , parentName: null
        , entityId: null
        , parentId: null
        , colId: null
        , applyAll: false
      }

      , mergeTagShow: false
      , valuesMap: {}
      , mergeFrom: null
      , mergeTo: null
      , mergeOptions: []

      , parentMap: {}
      , treeData: null
      , rowData: null
      , selectedParent: null

      , companyEditShow: false
      , companyId: null
      
      , noRowsMessage: null
      , noRowsOverlayComponentParams: null
      , isCellEditing: false
      , lastOpenColumnMenuParams: null

      //BadgeFilter related
      , badgeFilters: [
        // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
      ]
      , badgeFilterFields: [
        // { value: 'tagName', text: 'Tag Name' }
        // , { value: 'type', text: 'Type' }
      ]
      , badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
      }
      , badgeFilterFocus: false
      , badgeFilterModalOpened: 'close'
      , badgeFilterOptionFetchFunc: null

      //User view related
      , views: []
      , promptSaveShow: false
      , promptShareShow: false
      , saveName: null
      , saveProfile: null
      , saveIndex: -1
      , confirmDeleteViewShow: false
      , deleteViewIndex: -1
      , viewName: null
      , showInfo: []

      //nonAdmin mode
      , disableOk: false
      , deletedIds: []
      , deleteInProgressIds: []
      , status: null
      , errorJob: null
      , showErrorJob: false
      , sandboxProfile: {}
      , ignorePublished: false
      , warnPublished: false
      , ignoreRowSelection: false
      , testMode: false
    }
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const saveSelectorColumnSettings = (columns, skipCheck=true) => {
      if (this.profileKeySelector != null) {
        const newColumns = columns.filter(c => c.colId != 'rowSelector').map(c => getColumnDefs(c))
        const oldColumns = this.profileSettings[this.profileKeySelector]
        if (skipCheck) {
          this.profileSettings[this.profileKeySelector] = newColumns
          this.updateViewProfile()
          return
        }

        let hasChanged = false
        if (oldColumns == null) {
          hasChanged = true
        } else if (oldColumns.length != newColumns.length) {
          hasChanged = true
        } else {
          for (const [index, col] of oldColumns.entries()) {
            if (col.colId != newColumns[index].colId || 
                col.width != newColumns[index].width ||
                col.sort != newColumns[index].sort ||
                col.sortIndex != newColumns[index].sortIndex) {
              hasChanged = true
              break
            }
          }
        }
        if (hasChanged) {
          this.profileSettings[this.profileKeySelector] = newColumns
          this.updateViewProfile()
        }
      }
    }

    const self = this
    this.gridOptions = {}
    this.autoGroupColumnDef = {
      headerName: this.$t('sandbox.entity')
      , field: 'name'
      , pinned: 'left'
      , minWidth: 150
      , width: 400
      , menuTabs: ['columnsMenuTab']
      , cellRendererParams: {
        suppressCount: true,
        label: 'name',
        decorateCompany: true,
        innerRenderer: 'detailLinkCellRenderer',
        enableReadonlyStyle: true,
        checkbox: params => self.Checkable(params) || params.data.editable === false
      }
      , cellStyle: params => {
        if (params.data && params.data.editable === false) {
          return { 'font-weight': '600' };
        }
      }
      , comparator: getComparator('name')
    }

    
    this.columnDefs = []
    
    this.gridOptions = {
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        if (!self.ignoreColumnChanged) {
          const columns = params.api.getAllDisplayedColumns()
          saveSelectorColumnSettings(columns)
        }
      }
      , postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      }
      , onSortChanged: function(event) {
        //Clear last focused cell. Reset vertical scroll position to top. Clear range selection
        self.lastFocusedCell = null
        self.previousVScrollPosition = null
        event.api.clearRangeSelection()
        if (event.api.gridBodyCtrl && event.api.gridBodyCtrl.bodyScrollFeature) {
          event.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0)
        }
        //Update column setting
        const columns = event.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns)
      }
      , onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns, false)
      }
      , onGridSizeChanged: function(event) {
        const width = event.api.gridBodyCtrl && event.api.gridBodyCtrl.eBodyViewport? event.api.gridBodyCtrl.eBodyViewport.clientWidth : 0;
        self.autoGroupColumnDef.pinned = width < 800 ? '' : 'left';
        event.api.setGridOption('autoGroupColumnDef', self.autoGroupColumnDef);
      }
      , onFirstDataRendered: function(event) {
        self.gridApi.forEachNode( (node) => {
          node.setSelected(true);
        });
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null
          event.api.sizeColumnsToFit()
          
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns()
            saveSelectorColumnSettings(columns)
          })
        }
      }

      , onCellValueChanged: function(event) {
        let colId = event.column.colId
        const rowIndex = event.rowIndex
        let newValue = event.newValue
        let oldValue = event.oldValue
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex)

        if (event.column.colId == self.COLUMN_AGGRID_AUTOCOLUMN) {
          colId = 'name'
          newValue = rowNode.data.name
          oldValue = rowNode.data.oldName //oldName is added in autoGroupColumnDef.valueSetter.
        }
        
        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , entityId: rowNode.id
          , parentId: rowNode.data.pUuId
          , entityName: rowNode.data.name
          , color: event.colDef.color //Default Color. Used when creating new entity.
        }

        if (colId === self.COLUMN_AGGRID_AUTOCOLUMN || payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.triggeredByFillOperation) {
          self.pendingListByFillOperation.push(payload)
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload)
        } else {
          self.processValueChangedList.push(payload)
          self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
          self.processValueChanged(event.api)
        }
      }

      , onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          if (self.triggeredByFillOperation) {
            const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length)
            self.processValueChangedList.push(...pendingList)
            self.consolidateChangedCellValues(self.processValueChangedList)
            self.triggeredByFillOperation = false
            self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
            self.processValueChanged(event.api)
          }
          const cellRanges = event.api.getCellRanges()
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          })
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1]
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn.colId,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })()
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0] 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1)
              const newCellRanges = []
              let hasChanges = false

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true
                  break
                }
              }
              if (isStartCellClashed) {
                return
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex)
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i))                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range)
                  continue
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex
                const firstClashedCol = clashedColumns[0]
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0
                const lastClashedCol = clashedColumns[clashedColumns.length-1]
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  })
                  hasChanges = true
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol)
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  })
                  hasChanges = true
                }
              }
              
              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false
              if (newCellRanges.length > 0) {
                const allColumns = event.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0])
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1])
                const cloned = objectClone(newCellRanges)
                const newRanges = []
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0])
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1])
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    })
                    hasRangeMerged = true
                    continue
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true
                    continue
                  }
                  newRanges.push(cRange)
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges)
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  })
                }
                event.api.clearRangeSelection()
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange)
                }
              } else {
                //When thing settles down, update entitySelection variable.
                let selectedEntities = []
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex
                  
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i)
                    
                    if (rowNode == null) {
                      continue
                    }

                    //Treat any cell selection is ag-Grid-AutoColumn cell selection.
                    selectedEntities.push({ 
                        uuId: rowNode.data.uuId
                      , name: rowNode.data.name
                      , parent: rowNode.data.pUuId
                      , parentName: rowNode.data.pName
                      , colId: self.COLUMN_AGGRID_AUTOCOLUMN
                      , rowIndex: rowNode.rowIndex
                    })
                  }
                }
                
                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedEntities.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1
                  }
                  return 0
                })
                
                self.entitySelection.splice(0, self.entitySelection.length, ...selectedEntities)
                self.updatebtnDisableStatus()
              }
            }
          } else {
            //Clean up entitySelection when range selection is empty.
            self.entitySelection.splice(0, self.entitySelection.length)
            self.updatebtnDisableStatus()
          }
          event.api.refreshHeader()
        }
        
      }
      , onPaginationChanged: function(/** event */) {
        self.previousVScrollPosition = null
      }
      , onSelectionChanged: function(event) {
        self.entitySelection.splice(0, self.entitySelection.length, ...(event.api.getSelectedNodes().map(i => i.data)));

        const isArrayEmpty = Array.isArray(self.preselected) && self.preselected.length == 0;        

        let hasChanged = self.entitySelection.length > 0 && (self.preselected == null || isArrayEmpty);
        if (!hasChanged && self.preselected != null) {
          if (typeof self.preselected == 'string' && self.entitySelection.uuId != self.preselected) {
            hasChanged = true;
          } else if (self.preselected.length != self.entitySelection.length) {
            hasChanged = true;
          } else {
            for (const e of self.entitySelection) {
              if (!self.preselected.includes(e.uuId)) {
                hasChanged = true;
                break;
              }
            }
          }
        }
        self.disableOk = !hasChanged;
      }
      , getDataPath: function(data) {
        if (typeof data !== 'undefined') {
          const treepath = data.treepath.split(', ')
          return treepath
        }
        return [];
      }
      , isExternalFilterPresent: function() {
        return Object.hasOwn(self, 'searchData');
      }
      , doesExternalFilterPass: function doesExternalFilterPass(node) {
        if (node.data) {
          if (!self.searchData) {
            return true;
          }
          return self.searchData.find(d => d.uuId === node.data.uuId) != null ? true : false;
        }
        return false;
      },
      onRowSelected: (params) => {
        if (self.ignoreRowSelection) {
          return;
        }
        
        const children = params.node.allLeafChildren;
        for (const child of children) {
          if (params.node.selected) {
            child.setSelected(true);
          }
          else
          {
            child.setSelected(false);
          }
        }
        this.gridApi.forEachNode(node => {
          if (node.data &&
              node.data.head === params.node.data.uuId &&
              !params.node.selected) {
            node.setSelected(false);
          }
        });
        params.api.refreshHeader();
      }
    }
    this.context = { componentParent: self }
    this.defaultColDef = {
      sortable: true
      , resizable: true
      , minWidth: 100
      , hide: true
      , lockPinned: true
      , menuTabs: ['columnsMenuTab']
      , suppressKeyboardEvent: (params) => {
        if (params.event.keyCode === 46 
            || params.event.keyCode === 35 || params.event.keyCode == 36 
            || (params.event.keyCode == 37 && params.event.ctrlKey)) {
          return true
        } else if (params.event.keyCode == 13) {
          if (params.event.ctrlKey) {
            return true
          }
          if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
              (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
            return true
          }
        } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
          return true
        }
        return false
      }
      , cellRendererParams: { enableReadonlyStyle: true }
    }
    
  },
  mounted() {
    this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
    .finally(() => {
      //Call init() when component is newly created and mounted. 
      //The show value is already true, so it won't trigger the watch event.
      this.init() 
    })
    
    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus)
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
  },
  created() {
    this.entity = 'SANDBOX'
    this.formattedEntity = 'sandbox'
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn'
    this.cellCopyPrefix = `${this.entity}${CELL_COPY_CODE}`
    this.dataGroup = {
      stringGroup: ['name', 'identifier']
    }
    this.modelInfo = null
    this.profileSettings = null
    this.profileKeySelector = 'sandbox_publish';
    this.newToProfile = null
    this.exportData = false
    this.lastFocusedCell = null
    this.previousVScrollPosition = null
    this.isPasteInProgress = false
    this.nodeState = {}
    this.disableDuplicate = false
    this.disableDelete = false
    this.mandatoryFields = ['name']
    this.isInitCompleted = false;
    this.isGridReady = false;
    this.ignoreColumnChanged = true;
    this.toggleSelectorShow(this.show)
    document.addEventListener('keydown', this.keyDownHandler)
    this.items = {}

    const colorOptions = {
      none: true
      , department: false
      , company: false
    }
    
    Object.keys(colorOptions).forEach(key => this.coloring[key] = colorOptions[key])

    this.docImportProperties.splice(0, this.docImportProperties.length, ...[
      { value: 'color', text: this.$t('field.color') }
      , { value: 'identifier', text: this.$t('field.identifier') }
      , { value: 'name', text: this.$t('company.field.name') }
      , { value: 'task_path', text: this.$t('document.path') }
      , { value: 'tag', text: this.$t('field.tag') }
    ])

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
  },
  beforeDestroy() {
    this.nodeState = null
    this.isPasteInProgress = false
    this.previousVScrollPosition = null
    this.cellCopyPrefix = null
    this.lastFocusedCell = null
    this.exportData = false
    this.userId = null
    this.newToProfile = null
    this.profileSettings = null
    this.profileKeySelector = null
    this.profileKeyColoring = null
    this.COLUMN_AGGRID_AUTOCOLUMN = null
    this.autoGroupColumnDef = null
    this.columnDefs = null
    this.modelInfo = null
    this.dataGroup = null
    this.entity = null
    this.formattedEntity = null
    this.mandatoryFields = null
    this.isInitCompleted = false;
    this.isGridReady = false;
    document.removeEventListener('keydown', this.keyDownHandler)
    this.searchData = null
    this.items = null;
    
    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';
    this.gridApi = null;
  },
  watch: {
    async show(newValue) {
      //Reset value
      this.resetAlert()
      this.entityId = null
      this.searchFilter = ''
      this.filterText = ''
      this.searchData = null
      this.entitySelection.splice(0, this.entitySelection.length)
      this.rangeSelection.splice(0, this.rangeSelection.length)
      this.viewName = null
      this.skipViewNameReset = false
      this.lastFocusedCell = null
      this.previousVScrollPosition = null
      this.isPasteInProgress = false
      this.isCellEditing = false
      this.isInitCompleted = false
      this.rowData = null;
      
      if(newValue) {
        this.init()
      }
      this.toggleSelectorShow(newValue)
    },
  },
  computed: {     
    statusPercentage() {
      if (this.status &&
          this.status.propertyList &&
          typeof this.status.propertyList.progress !== 'undefined') {
        return this.status.propertyList.progress * 100;
      }
      return 0;
    },   
    masterCompany() {
      if (this.treeData === null) {
        return null;
      }
      
      const master = this.treeData.filter(c => c.type === 'Primary');
      return master.length > 0 ? master[0] : null;
    }
    , editorTitleLabel() {
      return this.$t(`sandbox.changes_title`)
    }
    , selectorTitleLabel() {
      return this.$t('sandbox.publish_modal_title', [this.$store.state.sandbox.name]);
    }
    , duplicateTitleLabel() {
      return this.duplicateTitle != null? this.duplicateTitle : this.$t(`entity_selector.${this.formattedEntity}_duplicate`)
    }
    , duplicateFieldNameLabel() {
      return this.$t(`entity_selector.${this.formattedEntity}_duplicate_field_name`)
    }
    , showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name')
    }
    , disableEdit() {
      return this.entitySelection.length != 1
    }
    , ecShowApplyAllCheckbox() {
      return this.ecConfirmDeleteEntities.length > 0
    }
    , ecConfirmDeleteStatement() {
      return ''
    }
    , overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('sandbox.grid.loading_changes') }</span>`;
    }
    , companyFilterIds() {
      if (this.$store.state.company && 
          this.$store.state.company.filterIds) {
        return this.$store.state.company.filterIds;
      }
      return this.$store.state.company?.uuId != null? [this.$store.state.company?.uuId] : [];
    }
    , filteredViews() {
      return this.views.filter(i => i.type == `${this.entity}_admin_selector`);
    }
    , priorityNavMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    }
    , priorityNavTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    }
  },
  methods: {
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
          return window.matchMedia(query).matches
      }
      if ('ontouchstart' in window) {
          return true
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
      return mq(query)
    }
    , getStatus() {
      sandboxService.status(this.$store.state.sandbox.value).then(response => {
        this.status = response.data;
        if (this.status.jobCase &&
          this.status[this.status.jobCase].feedbackList &&
          this.status[this.status.jobCase].feedbackList.length > 0) {
          const feedbackList = this.status[this.status.jobCase].feedbackList;
          
          this.parseFeedbackList(feedbackList);
          
          if (feedbackList[feedbackList.length - 1].clue &&
              (feedbackList[feedbackList.length - 1].clue !== "Ok" || 
               feedbackList[feedbackList.length - 1].clue !== "Created")) {
            this.status = "not migrated";
          }
          else {
            this.status = "migrated";
            this.resetAlert({ msg: this.$t('sandbox.published') })
          }
        }
      })
      .catch(e => {
        if (e.response.statusCode === 404) {
          this.status = "not migrated";
        }
      });
    }
    , initColumns() {
      const self = this;
      const colDefs = [
      this.getRowSelectorColumn(),
      {
        field: 'status',
        headerName: this.$t('sandbox.action'),
        hide: false,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            if (this.data && this.data.status) {
              return self.$t(`sandbox.status.${this.data.status.toLowerCase()}`);
            }
            return ''; 
          },
          clicked: function(field, e) {
            self.openDetail(`${field}${self.items[field].treepath}`)
          },
        },
        valueGetter: (params) => {
          if (params.data &&
             params.data.status) {
            return this.$t(`sandbox.status.${params.data.status.toLowerCase()}`);  
          }
          return '';
        }
      }];
      
      if (!this.testMode) {
        colDefs.push(
        {
          field: 'published',
          headerName: this.$t('sandbox.result'),
          cellRenderer: 'errorDetailLinkCellRenderer',
          cellRendererParams: {
            showErrorDetailFunc: params => {
              if (this.testMode) {
                this.errorJob = self.$t(`sandbox.error.${migrateStatusTest[params.data.uuId].toLowerCase()}`);
              }
              else {                
                this.errorJob = self.$t(`sandbox.error.${migrateStatus[params.data.uuId].toLowerCase()}`);
              }
              this.showErrorJob = true;
            },
            isErrorFunc: params => {
              if (self.testMode) {
                return self.isError(migrateStatusTest[params.data.uuId]);
              }
              return self.isError(migrateStatus[params.data.uuId]);
            }
          },
          hide: false,
          flex: 1
        });
      }
      else {
        colDefs.push(
        {
          field: 'test_published',
          headerName: this.$t('sandbox.result'),
          cellRenderer: 'errorDetailLinkCellRenderer',
          cellRendererParams: {
            showErrorDetailFunc: params => {
              if (self.testMode) {
                this.errorJob = self.$t(`sandbox.error.${migrateStatusTest[params.data.uuId].toLowerCase()}`);
                this.showErrorJob = true;
              }
              else {
                this.errorJob = self.$t(`sandbox.error.${migrateStatus[params.data.uuId].toLowerCase()}`);
                this.showErrorJob = true;
              }
            },
            isErrorFunc: params => {
              if (self.testMode) {
                return self.isError(migrateStatusTest[params.data.uuId]);
              }
              else {
                return self.isError(migrateStatus[params.data.uuId]);
              }
            }
          },
          hide: false,
          flex: 1
        });
      }

      colDefs.sort(columnDefSortFunc)
      
      this.columnDefs = colDefs
      
      if (this.gridApi) {
        this.gridApi.setGridOption('columnDefs', this.columnDefs);
      }
    }
    , async init() {
      const self = this;
      this.ignoreColumnChanged = true; //set true to ignore columnChanged triggered by default columnDef setup, will be set to false when loading ViewProfile()
      this.loadUserProfile(); // User profile holds entity views
      this.loadPublicProfile();  //Public profile holds entity views
      this.loadSandboxProfile();
      this.getStatus();
      this.initColumns();
      await this.loadViewProfile()
      if (this.isGridReady == true) {
        if (this.nonAdmin && this.preselected != null) {
          if (typeof this.preselected == 'string') {
            this.entitySelection.splice(0, this.entitySelection, { uuId: this.preselected });
            this.nodeState = {}
            this.nodeState[this.preselected] = { 
              expanded: true
              , selected: true
            };
          } else if (this.preselected.length > 0) {
            this.entitySelection.splice(0, this.entitySelection, ...this.preselected.map(i => ({ uuId: i })));
            this.nodeState = {}
            for (const s of this.preselected) {
              this.nodeState[s] = { 
                expanded: true
                , selected: true
              }
            }
          }
        }
        this.reloadData(false);
      }
      this.isInitCompleted = true;
    }
    , onGridReady(params) {
      this.gridApi = params.api;
      if (this.isInitCompleted == true) {
        if (this.nonAdmin && this.preselected != null) {
          if (typeof this.preselected == 'string') {
            this.entitySelection.splice(0, this.entitySelection, { uuId: this.preselected });
            this.nodeState = {}
            this.nodeState[this.preselected] = { 
              expanded: true
              , selected: true
            }
          } else if (this.preselected.length > 0) {
            this.entitySelection.splice(0, this.entitySelection, ...this.preselected.map(i => ({ uuId: i })));
            this.nodeState = {}
            for (const s of this.preselected) {
              this.nodeState[s] = { 
                expanded: true
                , selected: true
              }
            }
          }
        }
        this.reloadData(false)
      }
      this.isGridReady = true
    }
    , toggleSelectorShow(newValue) {
      this.selectorShow = newValue
    }
    , hidden() {
      this.alert = null
      this.inProgressShow = false;
      this.$emit('update:show', false)
      this.$emit('cancel')
    }
    , openEditor(isNew) {
      if(isNew) {
        this.entityId = `${this.entity}_NEW_${strRandom(5)}`
        if (this.entitySelection.length > 0) {
          const node = this.gridApi.getRowNode(this.entitySelection[0].uuId)
          this.selectedParent = node? node.data : null
        } else {
          this.selectedParent = this.$store.state.company ? this.$store.state.company : null
        }
        this.editorShow = true
        this.resetAlert()
      } else {
        if (this.entitySelection.length == 0) {
          return;
        }
        const id = this.entitySelection[0].uuId
        
        this.entityId = id
        this.editorShow = true
        this.resetAlert()
      }
      
    }
    , editorSuccess(payload) { 
      if (this.gridApi == null) {
        return
      }
      this.resetAlert({ msg: payload.msg })
      this.scrollToTop()
      this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
      this.reloadData(null, null, { redrawRows: true })
    }
    
    , openDetail(id){
      const node = this.gridApi.getRowNode(id)
      this.selectedParent = node? node.parent.data : null

      this.entityId = node.data.uuId
      const linkUuId = node.data.head;
      if (linkUuId) {
        if (linkUuId in this.items) {
          this.entityParent = this.items[linkUuId];
        }
        else {
          this.entityParent = null;
        }
      }
      this.editorShow = true

      this.resetAlert()
    }
    , resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertObj.msg = msg
      this.alertObj.state = alertState
      this.alertObj.msgDetails.title = detailTitle
      const list = this.alertObj.msgDetails.list
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details)
      } else {
        list.splice(0, list.length)
      }
    }
    , updateViewProfile({ clearViewName=true } = {}) {
      // clear the view name from profile
      if (clearViewName) {
        this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = this.viewName = null;
      }
      viewProfileService.update([this.profileSettings], this.userId)
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , createViewProfile() {
      viewProfileService.create([this.profileSettings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase]
        this.profileSettings.uuId = data[0].uuId
        this.newToProfile = true
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , async loadViewProfile() {
      const profileData = await this.$store.dispatch('data/viewProfileList', this.userId).then((value) => {  
        return value;
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      });
      
      if (profileData.length === 0) {
        await this.createViewProfile();
        this.useDefault = true; // load the default view, if the views are not loaded yet
        const defaultView = this.views.find(view => view.defaultView);
        if (defaultView) {
          this.loadViewSettings(defaultView);
        }
      }
      else {
        this.ignoreColumnChanged = false
        this.profileSettings = profileData[0]
        if (typeof this.profileSettings[this.profileKeySelector] != 'undefined') {
          this.skipViewNameReset = true;
          this.loadColumnSettings(this, this.profileSettings[this.profileKeySelector])
          const colorMenuOptions = {
            none: true
            , company: false
          }
          for (const optName in colorMenuOptions) {
            this.coloring[optName] = this.profileSettings[this.profileKeyColoring]? this.profileSettings[this.profileKeyColoring][optName] : colorMenuOptions[optName]  
          }
          
          this.filterText = typeof this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] !== 'undefined' ? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] : '';
          this.searchFilter = this.filterText;
          this.badgeFilters = Array.isArray(this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`])? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] : [];
          if (this.badgeFilters != null && this.badgeFilters.length > 0) {
            this.changeFilter();
          }
          this.viewName = this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] != null? this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] : null;
        } else {
          this.newToProfile = true
        }
      }
    }
    , loadColumnSettings(target, columns) {
      if (target == null || columns == null || columns.length == 0) {
        return
      }
      // order the columns based upon the order in 'columns'
      // 0 index column is reserved for rowSelector
      let idx = 1
      columns.forEach(function(col) {
        const index = target.columnDefs.findIndex((c) => c.field === col.colId)
        if (index !== -1) {
          target.columnDefs.splice(idx++, 0, target.columnDefs.splice(index, 1)[0])
        }
      })

      const autoGroupSetting = columns.find(i => i.colId == this.COLUMN_AGGRID_AUTOCOLUMN)
      
      if (autoGroupSetting != null) {
        const autoGroupColumnDef = target.autoGroupColumnDef
        autoGroupColumnDef.width = autoGroupSetting.width
        autoGroupColumnDef.sort = autoGroupSetting.sort
        autoGroupColumnDef.sortIndex = autoGroupSetting.sortIndex
        if (target.gridApi != null) {
          target.gridApi.setGridOption('autoGroupColumnDef', []);
          target.gridApi.setGridOption('autoGroupColumnDef', { ...autoGroupColumnDef });
        }
      }
      
      for (const column of target.columnDefs) {
        const setting = columns.filter(c => c.colId === column.colId || c.colId === column.field)
        if (setting.length === 0) {
          if (column.colId != 'rowSelector') {
            column.hide = true
          }
        }
        else {
          column.hide = false
          column.width = setting[0].width
          column.sort = setting[0].sort
          column.sortIndex = setting[0].sortIndex
        }
      }
      
      if (target.gridApi != null) {
        target.gridApi.setGridOption('columnDefs', cloneDeep(target.columnDefs))
      }
      return
    }
    , async getModelInfo(entity) {
      await this.$store.dispatch('data/info', {type: 'api', object: entity}).then(value => {
        this.modelInfo = value[entity].properties
      })
      .catch(e => {
        this.httpAjaxError(e)
      })
    }
    , applyFilter(pattern) {
      this.searchFilter = pattern.startsWith('(?is)')? pattern.substring(5) : pattern
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] = pattern
      this.updateViewProfile()
      this.previousVScrollPosition = null
      this.reloadData()
    }
    , showDuplicateDialog() {
      this.resetAlert()
      const data = this.gridApi.getRowNode(this.entitySelection[0].uuId).data
      if (this.entity == 'USER') {
        this.duplicateFirstname = data.firstName
        this.duplicateLastname = data.lastName
        this.duplicateEmail = ''
      } else {
        const origName = data.name
        this.duplicateName = `${origName} ${this.$t('entity_selector.duplicate_name_suffix')}`
      }
      this.duplicateShow = true
    }
    , duplicateOk() {
      this.duplicateEntity()
    }
    , duplicateEntity: debounce(async function() {
      this.duplicateInProgress = true
      
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t(`${this.duplicateFieldNameLabel}`)])
        })
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.processDuplicateOperation()
        } else {
          this.duplicateInProgress = false
        }
      })
    }, 100)

    , scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.elemId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
    , rowDelete() {
      this.resetAlert()
      const targetColEntities = []

      for (const task of this.entitySelection) {
        const rowNode = this.gridApi.getRowNode(task.uuId)
        // if we have already added the parent of this department it will be deleted when
        // the parent is deleted
        if (rowNode == null || targetColEntities.find(f => rowNode.data.treepath.includes(f.uuId))) {
          continue
        }
        targetColEntities.push({
          uuId: rowNode.id
          , name: rowNode.data.name
          , parent: rowNode.data.pUuId
          , parentName: rowNode.data.pName
          , colId: this.COLUMN_AGGRID_AUTOCOLUMN
        })
      }

      if (targetColEntities.length > 0) {
        //Prepare data for taskcol delete confirmation dialog
        this.ecConfirmDeleteEntities = targetColEntities
        this.prepareTargetColConfirmDeleteDialog()
      }
    }
    , getRowSelectorColumn() {
      return {
        headerName: ''
        , colId: 'rowSelector'
        , field: 'name'
        , width: 56
        , minWidth: 56
        , maxWidth: 56
        , hide: false
        , cellRenderer: 'rowSelectorCellRenderer'
        , cellRendererParams: {
          isReadOnly: !this.canEdit(this.entity)
          , enableReadonlyStyle: true
        }
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , suppressColumnsToolPanel: true

        , menuTabs: ['generalMenuTab']
        , headerComponent: 'selectionHeaderComponent'
        , resizable: false
        , headerCheckboxSelection: false
        , suppressFillHandle: true 
        , rowDrag: false
      }
    }
    , getMainMenuItems(params) {
      const self = this;
      const colId = params.column.colId;
      const headerName = params.column.colDef.headerName;
      if (colId != null && colId === 'rowSelector') {
        
        const menuItems = [];
        menuItems.push({
          name: this.$t('button.selectall'),
          action: () => {
            params.api.forEachNode( (node) => {
                node.setSelected(true);
            });
            params.api.refreshHeader();
          },
        });
        menuItems.push({
          name: this.$t('button.selectnone'),
          action: () => {
            params.api.forEachNode( (node) => {
                node.setSelected(false);
            });
            params.api.refreshHeader();
          },
        });

        const selectCellRangeByType = (api, type) => {
          self.ignoreRowSelection = true;
          api.forEachNode( (node) => {
            if (node.data.published == this.$t(type)) {
              node.setSelected(true);
            }         
            else {
              node.setSelected(false);
            }
          });
          api.refreshHeader();
          setTimeout(()=> {
            self.ignoreRowSelection = false;
          }, 100);
        }
        
        menuItems.push({
          name: this.$t('sandbox.publish_status.not_published'),
          action: () => {
            selectCellRangeByType(params.api, 'sandbox.publish_status.not_published');
          },
        });
        
        menuItems.push({
          name: this.$t('sandbox.publish_status.published'),
          action: () => {
            selectCellRangeByType(params.api, 'sandbox.publish_status.published');
          },
        });

        menuItems.push({
          name: this.$t('sandbox.publish_status.failed'),
          action: () => {
            selectCellRangeByType(params.api, 'sandbox.publish_status.failed');
          },
        });

        return menuItems;
      }
      return params.defaultItems;
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseDown(rowIndex=null) {
      if (rowIndex == null) {
        return
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map()
      const cellRanges = this.gridApi.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const columns = cRange.columns.map(i => i.colId)
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set())
          }
          const rCol = rowColumnMap.get(rowStartIndex)
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue
            }
            rCol.add(col)
          }
          continue
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set())
          }
          const rCol = rowColumnMap.get(i)
          for (const col of columns) {
            rCol.add(col)
          }
        }
      }

      const maxColumnsLength = this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length)
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key)
        }
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null } = {}) {
      const cellRanges = this.gridApi.getCellRanges()
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      })

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = this.gridApi.getFocusedCell()
        const focusedCellRowIndex = focusedCell.rowIndex
        const focusedCellColId = focusedCell.column.colId

        let cellRanges = this.gridApi.getCellRanges()
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = []
        
        let isDirectionUp = rowIndex < focusedCellRowIndex
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1)
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex)
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })

        this.gridApi.clearRangeSelection()
        for (const nRange of newRanges) {
          this.gridApi.addCellRange(nRange)
        }
        this.gridApi.setFocusedCell(focusedCellRowIndex, focusedCellColId)
        return
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = this.gridApi.getFocusedCell()
        if (curFocusedCell == null) {
          this.gridApi.clearFocusedCell()
          return
        }
        const rowIndex = this.gridApi.getFocusedCell().rowIndex
        this.gridApi.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        this.gridApi.setFocusedCell(rowIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null)
        return
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1]
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })()

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: this.gridApi
      })
    }
    , rowSelectorChanged({ cellRanges, originalRanges, lastRange, api }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true
            break
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex)

        //Prepare new cell ranges for previous ranges
        const newCellRanges = []
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex)

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: api.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null)
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null)
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection()
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange)
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay
          const selection = new Set()
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex
            let endIdx = range.rowEndIndex
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex
              endIdx = range.rowStartIndex
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId)
            }
          }
        }
        
      }
    }
    , async processValueChanged(api, { customProgressLabel=null } = {}) {
      if (customProgressLabel != null) {
        this.inProgressLabel = customProgressLabel
      } else {

        //Find out if the next entity is a company or department, show respective progress label
        //When no next entity is found, don't override previous progress label with new progress label.
        let nextUuId = null
        if (this.processDateValueChangedList.length > 0) {
          nextUuId = this.processDateValueChangedList[0].entityId
        } else if (this.processValueChangedList.length > 0) {
          nextUuId = this.processValueChangedList[0].entityId
        }
        
        let isCompany = false
        if (nextUuId != null) {
          const rowNode = api.getRowNode(nextUuId)
          if (rowNode != null) {
            isCompany = typeof rowNode.data.type !== 'undefined'
          }
          this.inProgressLabel = this.$t(`entity_selector.${isCompany? 'company': this.formattedEntity}_update_progress`)
        }
      }
      this.inProgressShow = true
      
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250))
        } while(this.pendingProcessRequestList.length > 250)
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length)
          this.pendingRequestBatchList.push(requests)
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length)
          this.resetAlert()
          for (const b of bList) {
            await compositeService.exec(b)
             .then(response => {
              if (response.status == 207 && response.data != null && response.data.jobCase != null) {
                const feedbackList = response.data[response.data.jobCase]
                if (feedbackList != null && feedbackList.length > 0) {
                  const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                  this.resetAlert({ msg: msg != null? msg: this.$t('entity_selector.error.partial_update_operation'), alertState: alertStateEnum.WARNING })
                  this.scrollToTop()
                }
              }
            })
            .catch(e => {
              this.inProgressShow = false
              this.httpAjaxError(e)
            })
          }
          this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top

          this.reloadData(true, () => {
            setTimeout(() => {
              this.resetFocus(api, this.lastFocusedCell);
            }, 0);
            this.inProgressShow = false;
          });
        } else {
          this.inProgressShow = false
        }
        return
      }
      
      let isPendingOtherProcess = false;
      do {
        let currentItem = null
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift()
        } else {
          currentItem = this.processValueChangedList.shift()
        }

        
        // const colId = currentItem.colId
        const property = currentItem.property
        const oldValue = currentItem.oldValue
        const newValue = currentItem.newValue
        // const rowData = currentItem.data
        let entityId = currentItem.entityId

        let handler = this.getValueChangedHandler()
        
        if (handler[property] != null && typeof handler[property].execute === 'function') {
          let result
          const oVal = oldValue != null? oldValue : handler[property].defaultValueIfNull
          if (handler[property].isAsync) {
            result = await handler[property].execute(entityId, oVal, newValue)
          } else {
            result = handler[property].execute(entityId, oVal, newValue)
          }
          if (result.status == operationStatus.SUCCESS) {
            if (result.isDataProp) {
              //This flow is meant for handling custom data format for entity's property (not link/edge)
              const entityObj = { uuId: entityId }
              const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
              const newVal = result.value
              if (typeof newVal == 'string' && newVal.trim().length == 0 && blankToNullList.includes(property)) {
                entityObj[property] = null
              } else {
                entityObj[property] = newVal
              }
              const rowNode = this.gridApi.getRowNode(entityId)
              const isCompany = rowNode != null && rowNode.data != null && typeof rowNode.data.type != 'undefined'
              this.pendingProcessRequestList.push({
                method: 'PUT',
                invoke: `/api/${isCompany? 'company' : 'department'}/update`,
                body: [entityObj],
                vars: [],
                note: `${isCompany? 'company' : this.entity}_update__${property}`
              })
            } else if (result.value.length > 0) {
              //This flow is meant for handling custom data format for entity's property which is a link/edge
              this.pendingProcessRequestList.push(...result.value)
            }
          } else if (result.status == operationStatus.ABORT) {
            const prop = result.property != null? result.property : property
            const val = Object.hasOwn(result, 'value')? result.value : oldValue
            
            const rowNode = api.getRowNode(entityId)
            if (rowNode != null) {
              const data = rowNode.data
              data[prop] = val
              rowNode.setData(data)
            }
          }
          
        } else if ('tag' === property) {
          const request = await this.updateTags(entityId, oldValue, newValue)
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request)
          }
        } else { // update entity
          const entityObj = { uuId: entityId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
          if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            entityObj[property] = null
          } else {
            entityObj[property] = newValue
          }
          const rowNode = this.gridApi.getRowNode(entityId)
          const isCompany = rowNode != null && rowNode.data != null && typeof rowNode.data.type != 'undefined'
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: `/api/${isCompany? 'company' : 'department'}/update`,
            body: [entityObj],
            vars: [],
            note: `${isCompany? 'company': this.entity}_update__${property}`
          })
        }
        
      } while(this.processValueChangedList.length > 0);

      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    }
    , resetFocus(api, cell) {
      api.clearRangeSelection()
      if (cell != null && cell.rowIndex != null && cell.colId != null) {
        api.setFocusedCell(cell.rowIndex, cell.colId, null)
        api.addCellRange({
          rowStartIndex: cell.rowIndex
          , rowEndIndex: cell.rowIndex
          , columnStart: cell.colId
          , columnEnd: cell.colId
        })
      }
    }
    , async updateTags(entityId, oldTagList, newTagList) {
      const oldTagNames = oldTagList != null? objectClone(oldTagList) : []
      const oldTagFilters = []
      for(const t of oldTagNames) {
        if (t == null || t.trim().length == 0) {
          continue
        }
        oldTagFilters.push({ value: t.trim(), regex: false })
      }      
      let oldTags
      if (oldTagFilters.length > 0) {
        oldTags = await tagService.list_with_filters({filters: oldTagFilters}).then((response) => {
          return response.data.filter(i => i.uuId != null)
        })
      } else {
        oldTags = []
      }

      const newTagNames = objectClone(newTagList)
      const newTagFilters = []
      if (Array.isArray(newTagNames)) {
        for(const t of newTagNames) {
          if (t == null || t.trim().length == 0) {
            continue
          }
          newTagFilters.push({ value: t.trim(), regex: false })
        }
      }      
      let newTags
      if (newTagFilters.length > 0) {
        newTags = await tagService.list_with_filters({filters: newTagFilters}).then((response) => {
          const data = response.data
          const list = []
          for (const f of newTagFilters) {
            const found = data.find(i => i.name.localeCompare(f.value, undefined, { sensitivity: 'base' }) === 0)
            if (found != null) {
              list.push(found)
            }else {
              list.push({ uuId: null, name: f.value })
            }
          }
          return list
        })
      } else {
        newTags = []
      }
      return this.updateTag(entityId, oldTags, newTags)
    }
    , updateTag(entityId, oldTagList, updatedTagList) {
      // let _entityPath = this.entity
      // if (_entityPath != null && _entityPath.indexOf('__') > -1) {
      //   _entityPath = _entityPath.replace('__', '/')  
      // }
      
      const originTagList = oldTagList != null && Array.isArray(oldTagList)? oldTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : []
      const tagList = updatedTagList != null && Array.isArray(updatedTagList)? updatedTagList.map(i => { return { uuId: i.uuId, name: i.name } }) : []
      const toAdd = []
      const toUpdate = []
      const unchangedIds = []
      for(const tag of tagList) {
        const index = originTagList.findIndex(j => j.uuId === tag.uuId)
        if(index == -1) {
          toAdd.push(tag)
        } else {
          unchangedIds.push(tag.uuId)
        }
      }

      const toAddIds = toAdd.map(i => i.uuId)
      const toUpdateIds = toUpdate.map(i => i.uuId)
      const toRemove = originTagList.filter(i => !toAddIds.includes(i.uuId) && !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId))
      const requests = []

      const rowNode = this.gridApi.getRowNode(entityId)
      const isCompany = rowNode != null && rowNode.data != null && typeof rowNode.data.type != 'undefined'

      if(toAdd.length > 0) {
        const addTagReqTemplate = (refId, tagName) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
            })
          })
          return {
            method: 'POST',
            invoke: '/api/tag/add',
            body: [{ 
            name: tagName
            }],
            vars: [{ 
              name: refId,
              path: '$.feedbackList.uuId'
            }],
            note: `addTag__${refId}`
          }
        }
        const addTagLinkReqTemplate = (refId, entityId, tagList) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
              , name: i.name
            })
          })
          
          return {
            method: 'POST',
            invoke: `/api/${isCompany? 'company' : 'department'}/link/tag/add`,
            body: { 
              uuId: entityId,
              tagList: list
            },
            vars: [],
            note: `${isCompany? 'COMPANY' : 'DEPARTMENT'}_AddTagLink__${refId}`
          }
        }
        
        // for (const [index, tag] of toAdd.entries()) {
        for (const tag of toAdd) {
          let refId = randomString(8)
          if (tag.uuId == null) {
            refId = `tagUuId_${refId}`
            tag.uuId = `@{${refId}}`
            requests.push(addTagReqTemplate(refId, tag.name))
          }
          requests.push(addTagLinkReqTemplate(`${entityId}_${refId}`, entityId, [tag]))
        }
      }

      if(toRemove.length > 0) {
        const removeTagLinkReqTemplate = (refId, entityId, tagList) => {
          const list = []
          tagList.forEach(i => {
            list.push( {
              uuId: i.uuId
            })
          })
          return {
            method: 'POST',
            invoke: `/api/${isCompany? 'company' : 'department'}/link/tag/delete`,
            body: { 
              uuId: entityId,
              tagList: list
            },
            vars: [],
            note: `${isCompany? 'COMPANY' : 'DEPARTMENT'}_RemoveTagLink__${refId}`
          }
        }
        for (const [index, tag] of toRemove.entries()) {
          requests.push(removeTagLinkReqTemplate(`${index}_${entityId}`, entityId, [tag]))
        }
      }

      return requests
    }
    , keyDownHandler(e) {
      if (!this.show) {
        return
      }
      
      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return
      }

      if (e.key == 'Delete' && e.target.closest('.generic-selector') == null 
          && this.gridApi.getCellRanges() != null && this.gridApi.getCellRanges().length > 0) {
        //Construct the necessary payload value and call the cellKeyDown method 
        this.onCellKeyDown({ api: this.gridApi, event: { keyCode: e.keyCode, key: e.key } })
      } else if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridApi
        if (api == null) {
          return
        }
        e.stopPropagation()
        e.preventDefault()
        if (e.shiftKey) {
          return
        }
        const rowCount = api.getDisplayedRowCount()
        if (rowCount == 0) {
          return
        }

        const firstRowIndex = 0
        const lastRowIndex = rowCount - 1
        const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
        const startColumn = columns[0]
        const lastColumn = columns[columns.length - 1]

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex
        const colId = e.keyCode == 36? startColumn : lastColumn
        const vPosition = e.keyCode == 36? 'top' : 'bottom'

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition)
        } else {
          const focusedCell = api.getFocusedCell()
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null)
          }
        }
        return
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridApi
        const focusedCell = api.getFocusedCell()
        if (focusedCell != null) {
          const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null)
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridApi
        const totalCount = api.getDisplayedRowCount()
        if (totalCount == 0) {
          return
        }
        const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)

        api.clearRangeSelection()
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: totalCount - 1,
          columns
        })
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell()
        if (focusCell != null) {
          return
        }
        api.setFocusedCell(0, columns[0], null)
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation()
        e.preventDefault()
      }

    }
    //Refer in detailLink.vue (CellRenderer)
    , getRowColor(data) {
      if (data && data.color) 
        if (typeof data.type === 'undefined' && this.coloring[this.formattedEntity]) {
          return data.color
        } else if (typeof data.type !== 'undefined' && this.coloring['company']) {{
          return data.color  
        }
      }
    }
    , navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex
        const colId = pColId
        api.clearRangeSelection()
        api.setFocusedCell(rowIndex, colId, null)
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        })
        api.ensureIndexVisible(rowIndex, vPosition)
        api.ensureColumnVisible(colId, 'auto')
      })
    }
    , onCellKeyDown(params) {
      if (params.event.key == 'Delete' && this.canDelete(this.entity)) {
        const cellRanges = params.api.getCellRanges()
        //Prepare cell information
        const targetColEntities = []
        const processedCells = [] //Used to elimate duplicate records.
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex 
          
          const columns = cRange.columns
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx)
            
            for (const column of columns) {
              const colId = column.colId
              const rowId = rowNode.data.uuId
              const found = processedCells.find(i => rowId == i.rowId && (this.COLUMN_AGGRID_AUTOCOLUMN == i.colId || colId == i.colId))
              if (found != null) {
                continue //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId })

              //Handle 'targetColumn' cell
              //Brief: Delete targetColumn cell means remove the whole row
              if (this.COLUMN_AGGRID_AUTOCOLUMN == colId) {
                targetColEntities.push({
                   uuId: rowNode.id
                  , name: rowNode.data.name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                })
                continue
              }

              if (rowNode.data[colId] == null) {
                continue //Skip when the property value is null.
              }

              //Handle non targetColumn cell
              //Skip when the cell is not editable
              let isEditable
              if (typeof this.defaultColDef.editable === 'function') {
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId)
              if (typeof colDef.editable === 'function') {
                isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable
              }
              if (!isEditable) {
                continue
              }
              
              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , entityId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                // , taskType: rowNode.data.taskType
                // , isTaskCol: false
                , value: rowNode.data[colId]
              })
            }
          }
        }
        
        if (targetColEntities.length > 0) {
          //Prepare data for taskcol delete confirmation dialog
          this.ecConfirmDeleteEntities = targetColEntities
          this.prepareTargetColConfirmDeleteDialog()
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell()
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api
        if (api == null) {
          return
        }

        const focusedCell = api.getFocusedCell()
        if (focusedCell == null) {
          return
        }

        const cellRanges = api.getCellRanges()
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex
          }
          return false
        })
        if (fRange == null) {
          return
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex
        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId)
        return
      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false
        const api = params.api
        if (api == null) {
          return
        }
        const fRowIndex = params.rowIndex
        const column = params.column
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data
          editable = column.colDef.editable({ data })
        }
        if (!editable) {
          let rowIndex
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1
            if (rowIndex < 0) {
              rowIndex = 0
            }
          } else {
            rowIndex = fRowIndex + 1
            const lastRowIndex = api.getDisplayedRowCount() - 1
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null)
          }
        }
      }
    }
    , fillOperation(params) {
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.rowNode
        , data: params.rowNode.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return params.currentCellValue
      }

      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration']
      if (blackListed.includes(params.column.colId)) {
        return params.currentCellValue
      }

      //Used to signal batch operation in onCellValueChanged event.
      this.triggeredByFillOperation = true

      //Find the source Column, source rowNode based on the available details in params object.
      const tgtRowNode = params.rowNode
      const targetColId = params.column.colId
      let srcRowNode = null 
      let sourceColId = null
      
      if (params.direction == 'left' || params.direction == 'right') {
        srcRowNode = tgtRowNode //Default: Same row as target.
        const columns = params.api.getColumnState().filter(i => i.hide != true).map(i => i.colId)
        const targetColIdx = columns.findIndex(i => i == targetColId)
        let sourceColIdx = targetColIdx - params.currentIndex - 1
        if (params.direction == 'left') {
          sourceColIdx = targetColIdx + params.currentIndex + 1
        }
        sourceColId = columns[sourceColIdx]
      } else {// direction: up or down
        let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1
        if (params.direction == 'up') {
          srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1
        }
        srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex)
        sourceColId = targetColId //Default: Same column as source.
      }

      let srcProperty = sourceColId
      let tgtProperty = targetColId
      //Check if the source and target's property value type are compatible
      if (srcProperty != tgtProperty) {
        if (sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          srcProperty = 'name'
        }
        if (targetColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          tgtProperty = 'name'
        }
        const compatibleResult = this.isPropertyCompatible(srcProperty, tgtProperty)
        if (!compatibleResult.status) {
          return tgtRowNode.data[compatibleResult.colId != null? compatibleResult.colId : tgtProperty]
        }
      }

      let srcValue = srcRowNode.data[sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN? 'name' : sourceColId]
      let tgtValue = objectClone(tgtRowNode.data[targetColId])

      //Skip when the target cell is a mandatory field and the new value is either blank or null
      if (this.mandatoryFields.length > 0 && 
          this.mandatoryFields.includes(tgtProperty) && 
          (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
            Array.isArray(srcValue) && srcValue.length == 0)) {
        return tgtValue
      }

      const result = this.propertyCopyOperation(tgtProperty, srcValue, tgtRowNode.data)
      if (result.status == operationStatus.ABORT) {
        return tgtValue
      }
      return result.value
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data
      let srcColId = params.column.colId
      if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        srcColId = 'name'
      }

      const srcRowId = rowData.uuId
      const srcRowData = params.api.getRowNode(srcRowId).data
      
      const source = {
        colId: srcColId
        // , data: objectClone(srcRowData)
        , value: srcRowData[srcColId]
        , property: srcColId
        , entityId:   srcRowData.uuId
        // , parentId: srcRowData.pUuId
        // , taskName: srcRowData.name
        // , taskType: srcRowData.taskType
      }
      return this.cellCopyPrefix+JSON.stringify(source)
    }
    , processCellFromClipboard(params) {
      let rowData = params.node.data
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.node
        , data: params.node.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , context: params.context
      }
      
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return rowData[params.column.colId]
      }

      let colId = params.column.colId
      
      if (colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        colId = 'name'
      }
      
      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration']
      if (blackListed.includes(colId)) {
        return rowData[colId]
      }

      let source = params.value
      //Defensive code: return original value when the value is not from aggrid cell copy.
      if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
        return rowData[colId]
      }

      //Restore value to JSON object.
      source = source.substring(this.cellCopyPrefix.length)
      source = JSON.parse(source)
      
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , value: rowData[colId]
        , property: colId
        , entityId:   rowData.uuId
        , parentId: rowData.pUuId
        , entityName: rowData.name
      }

      if (source.property != target.property) {
        const compatibleResult = this.isPropertyCompatible(source.property, target.property)
        if (!compatibleResult.status) {
          return rowData[compatibleResult.colId != null? compatibleResult.colId : target.property]
        }
      }

      let srcValue = source.value

      //Skip when the target cell is a mandatory field and the new value is either blank or null
      if (this.mandatoryFields.length > 0 && 
          this.mandatoryFields.includes(target.property) && 
          (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
            Array.isArray(srcValue) && srcValue.length == 0)) {
        return target.value
      }
      
      const result = this.propertyCopyOperation(target.property, srcValue, target.data)
      if (result.status == operationStatus.ABORT) {
        return result.colId != null? 
          rowData[result.colId] : (result.value != null? result.value : target.value)
      }
      return result.value
    }
    , prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , entityId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      return target
    }
    , ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtNotes = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtNotes = tgtValue.single
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        // return rowData[colId] //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift()
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0])
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier
      }
      delete newNote.uuId
      tgtNotes.unshift(newNote)
      return { action: 'add', newValue: tgtNotes }
    }
    , ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtStaffs = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtStaffs = tgtValue.single
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = []
      if (srcValue.length > 0) {
        const updatedStaffs = []
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId)
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization
            updatedStaffs.push(found)
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration
            delete srcStaff.durationAUM
            updatedStaffs.push(srcStaff)
          }
        }
        newValue = updatedStaffs
      }
      return { action: 'add', newValue }
    }
    , ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex)
      if (srcRowNode == null) {
        return
      }
      
      //Prepare source (property) value
      const srcRowData = srcRowNode.data
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix)

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value
        source.value = valueObj.single 
        source.property = valueObj.property
        source.taskId = valueObj.uuId != null? valueObj.uuId : null
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return
      }

      const pendingFilled = []
      const cellRanges = api.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }})
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i)
          if (curRowNode == null) {
            continue
          }

          const tgtRowData = curRowNode.data
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color })

            if (source.property != target.property) {
              const compatibleResult = this.isPropertyCompatible(source.property, target.property)
              if (!compatibleResult.status) {
                continue
              }
            }

            let srcValue = source.value

            const result = this.propertyCopyOperation(target.property, srcValue, tgtRowData)
            if (result.status == operationStatus.ABORT) {
              continue
            }
            target.newValue = result.value
            pendingFilled.push(target)
          }
        }
      }
      
      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled)
        this.inProgressLabel = this.$t('task.progress.updating_tasks')
        this.processValueChanged(api)
      }
    }
    , onPasteStart(/** params */) {
      this.isPasteInProgress = true
    }
    , onPasteEnd(params) {
      this.isPasteInProgress = false
      this.consolidateChangedCellValues(this.processValueChangedList)
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks')
        this.processValueChanged(params.api)  
      }
    }
    , consolidateChangedCellValues(valueChangedList) {
      if (valueChangedList == null || !Array.isArray(valueChangedList)) {
        return
      }
      
      // if (Object.hasOwn(this.entityService, 'consolidatePropertyGroupWhenPaste')) {
      //   const resultList = this.entityService.consolidatePropertyGroupWhenPaste(valueChangedList)
      //   valueChangedList.splice(0, valueChangedList.length, ...resultList)
      // }
    }
    , navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition
      const nextCellPosition = params.nextCellPosition
      if (nextCellPosition == null) {
        return previousCellPosition
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection()
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition
      }
      return nextCellPosition
    }
    , tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab in cell editing.
      const curColId = params.previousCellPosition.column.colId
      const columns = params.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector')
      let rowIndex = params.previousCellPosition.rowIndex
      let index = columns.findIndex(i => i.colId == curColId)
      let nextColIdx
      if (index == 0 && params.backwards) {
        rowIndex -= 1
        nextColIdx = columns.length - 1
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1
        nextColIdx = 0
      } else if (params.backwards) {
        nextColIdx = index - 1
      } else {
        nextColIdx = index + 1
      }
      const column = columns[nextColIdx]

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex
        this.tabColumn = column
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null
          this.tabColumn = null
          this.tabTimeoutId = null
        }, 0)
        setTimeout(() => {
          params.api.clearRangeSelection()
          params.api.setFocusedCell(rowIndex, column.colId)
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null)
          params.api.ensureColumnVisible(column.colId, 'auto')
        }, 0)
        return params.previousCellPosition
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null
    }
    , cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    }
    , async deleteCell() {
      //deleteCell() expects only non targetColumn cell.
      const pendingItems = []
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length)
      for (const cell of cells) {
        // Skip deleting cell when the property of the cell is a mandatory property/field of entity.
        if (this.mandatoryFields.includes(cell.property)) {
          continue
        }
        cell.oldValue = cell.value
        if (cell.property == 'color') {
          cell.newValue = null
        } else {
          //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
          const result = this.propertyDeleteOperation(cell.property, cell.data)
          if (result.state == operationStatus.ABORT) {
            continue
          }
          
          cell.newValue = result.value
        }
        delete cell.value
        pendingItems.push(cell)
      }
      
      if (pendingItems.length > 0) {
        const data = []
        for (const item of pendingItems) {
          const rowData = item.data //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          rowData[item.colId] = item.newValue
          data.push(rowData)
        }
        this.gridApi.applyTransaction({ update: data })
        this.processValueChangedList.push(...pendingItems)
      }
      
      // this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_delete_progress`)
      this.processValueChanged(this.gridApi, { customProgressLabel: this.$t(`entity_selector.${this.formattedEntity}_delete_progress`) })
    }
    , ecConfirmDeleteOk() {
      const entities = [{ 
        entityId: this.entityCol.entityId
        , parentId: this.entityCol.parentId 
        , colId: this.entityCol.colId
      }]
      if (this.entityCol.applyAll == true) {
        entities.push(...this.ecConfirmDeleteEntities.map(i => {
          return {
            entityId: i.uuId
            , parentId: i.parent
            , colId: i.colId
          }
        }))
        this.ecConfirmDeleteEntities.splice(0, this.ecConfirmDeleteEntities.length)
      }
      
      const deleteTaskReqTemplate = (entityId) => {
        return {
          method: 'POST',
          invoke: '/api/department/delete',
          body: [{
            uuId: entityId,
          }],
          vars: [],
          note: `${this.entity}_delete__${entityId}`
        }
      }
      const toBeUpdated = []
      const toBeRemoved = []
      // const leafChildrenToBeRemoved = [] //Used to remove child rows of deleted summary task in grid for better visual.
      const toBeRemovedRowIndexes = []
      for(const entity of entities) {
        this.pendingProcessRequestList.push(deleteTaskReqTemplate(entity.entityId))
        
        // if (entity.colId == this.targetColumnId) {
          const rowNode = this.gridApi.getRowNode(entity.entityId)
          const rowData = objectClone(rowNode.data)
          for (const r of rowNode.allLeafChildren) {
            toBeRemoved.push(objectClone(r.data));
            toBeRemovedRowIndexes.push(r.rowIndex);
          }
          // //Collect child row data and will be used in grid applytransaction to remove rows for better visual.
          // if (rowData.taskType == 'Project') {
          //   if (rowNode.allLeafChildren == null || rowNode.allLeafChildren.length < 2) {
          //     continue
          //   }
          //   const rowNodeChildren = rowNode.allLeafChildren
          //   for (let i = 1, len = rowNodeChildren.length; i < len; i++) {
          //     leafChildrenToBeRemoved.push(objectClone(rowNodeChildren[i].data))
          //   }
          // }
          
        // } 
        // else {
        //   // const rowData = objectClone(this.gridApi.getRowNode(task.parentId).data)
        //   // rowData[entity.colId].single = TaskViewPropertyUtil.getEmptyDataModel(rowData[task.colId].property, rowData[task.colId].data).value
        //   // delete rowData[task.colId].uuId
        //   // toBeUpdated.push(rowData)
        // }
      }

      //Adjust focused cell position to cell above (if possible) when the row which focused cell belongs to is removed.
      let suggestedRowIndex = -1
      if (toBeRemovedRowIndexes.length > 0) {
        const currentFocusedCell = this.gridApi.getFocusedCell()
        if (currentFocusedCell != null) {
          const rowIndex = currentFocusedCell.rowIndex
          const colId = currentFocusedCell.column.colId
          const rowPinned = currentFocusedCell.rowPinned
          const isFocusedCellAffected = toBeRemovedRowIndexes.includes(rowIndex)
          if (isFocusedCellAffected) {
            suggestedRowIndex = rowIndex - 1
            while (toBeRemovedRowIndexes.includes(suggestedRowIndex)) {
              suggestedRowIndex = suggestedRowIndex - 1
            }
          }
          if (suggestedRowIndex > -1) {
            this.gridApi.clearRangeSelection()
            this.gridApi.setFocusedCell(suggestedRowIndex, colId, rowPinned)
          }
        }
      }

      if (toBeUpdated.length > 0 || toBeRemoved.length > 0) {
        // if (leafChildrenToBeRemoved.length > 0) {
        //   toBeRemoved.push(...leafChildrenToBeRemoved)
        // }
        this.gridApi.applyTransaction({ update: toBeUpdated.length > 0? toBeUpdated : null, remove: toBeRemoved.length > 0? toBeRemoved : null })
        
      }
      
      setTimeout(() => {
        this.prepareTargetColConfirmDeleteDialog()
      }, 300)
    }
    , prepareTargetColConfirmDeleteDialog() {
      if (this.ecConfirmDeleteEntities == null || this.ecConfirmDeleteEntities.length == 0) {
        this.deleteCell()
        return
      }
      
      const entity = this.ecConfirmDeleteEntities.shift()
      this.entityCol.entityId = entity.uuId
      this.entityCol.entityName = entity.name
      this.entityCol.parentName = entity.parent == null || entity.parent == 'ROOT'? null : entity.parentName
      this.entityCol.applyAll = false
      this.entityCol.parentId = entity.parent
      this.entityCol.colId = entity.colId
      this.inProgressShow = false
      this.ecConfirmDeleteShow = true
    }
    , ecConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deleteCell()
      }
    }
    , propertyDeleteOperation(property, data) {
      //Custom handling logic
      let handler = this.getPropertyDeleteHandler(this);
      
      if (handler[property] != null) {
        const val = handler[property]
        if (typeof val === 'function') {
          const result = val(data)
          if (result != null && operationStatusKeys.includes(result.status)) {
            return { found: true, value: result.value, status: result.status }  //found prop is used for debug
          }
        } else {
          return { found: true, value: val, status: operationStatus.SUCCESS } //found prop is used for debug
        }
      }

      //Default handling logic
      let value = data[property]
      if (typeof value == 'string') {
        value = ''
      } else if (typeof value == 'number') {
        value = 0
      } else if (typeof value == 'object') {
        value = { uuId: null }
      } else if (Array.isArray(value)) {
        value = []
      }
      return { found: false, value, status: operationStatus.SUCCESS }
    }
    , propertyCopyHandler() {
      let maxNameLength = 200
      let maxIdentifierLength = 200
      if (this.modelInfo != null) {
        let val = this.modelInfo.filter(info => info.field === 'name')
        if (val.length > 0) {
          maxNameLength = val[0].max
        }
        val = this.modelInfo.filter(info => info.field === 'identifier')
        if (val.length > 0) {
          maxIdentifierLength = val[0].max
        }
      } 

      //Expected format when return value is a function:
      //{ value, status } or
      //{ value, status, colId } when status is ABORT
      //Possible status: 'SUCCESS' | 'ABORT'
      //colId is optional but is useful for specifying a different colId as reset value.
      return {
        color: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.trim().length == 0) {
            value = null
          }
          return { value, status: 'SUCCESS' }
        }
        , name: (srcValue /**, tgtData*/) => {
          
          let value = srcValue
          if (srcValue != null && srcValue.length > maxNameLength) {
            value = srcValue.substring(0, maxNameLength)
          }
          return { value, status: 'SUCCESS' }
        }
        , identifier: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && value.length > maxIdentifierLength) {
            value = srcValue.substring(0, maxIdentifierLength)
          }
          return { value, status: 'SUCCESS' }
        }
      }
    }
    , propertyCopyOperation(property, srcValue, tgtData) {
      const handler = this.propertyCopyHandler()
      
      //When no handler for specific property, just copy value
      if (!Object.hasOwn(handler, property)) {
        return { value: srcValue, status: operationStatus.SUCCESS }
      }

      const val = handler[property]
      if (typeof val === 'function') {
        const result = val(srcValue, tgtData)
        if (result != null && operationStatusKeys.includes(result.status)) {
          return result
        }
      }
      return { value: val, status: operationStatus.SUCCESS }
    }
    , httpAjaxError(e) {
      console.error(e) //eslint-disable-line no-console
      const response = e.response;
      let alertMsg = this.$t('error.internal_server')
      if (response) {
        if (403 === response.status) {
          alertMsg = this.$t('error.authorize_action')
        } else if (422 === response.status) {
          const feedbackList = response.data[response.data.jobCase];
          const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
          if (msg != null) {
            alertMsg = msg;
          }
        }
      } 
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
      this.scrollToTop();
    }
    , mergeTag() {
      this.mergeTo = null
      if (this.entitySelection.length == 1) {
        const rowNode = this.gridApi.getRowNode(this.entitySelection[0])
        if (rowNode != null && rowNode.data != null) {
          this.mergeFrom = rowNode.data.name
        }
      }
      this.mergeTagShow = true
    }
    , saveNodeState() {
      const self = this;
      this.nodeState = {};
      if (this.gridApi) {
        this.gridApi.forEachNode(node => {
          self.nodeState[node.id] = { 
            expanded: node.expanded
          };
        });
      }
    }
    , restoreNodeState() {
      const self = this;
      if (this.nodeState && this.gridApi) {
        this.gridApi.forEachNode(node => {
          if (node.id in self.nodeState) {
            node.setExpanded(self.nodeState[node.id].expanded)
            if (self.nodeState[node.id].selected) {
              node.setSelected(true)
            }
          }
        });
        this.nodeState = {};
      }
    }
    , async reloadData(saveState = true, callbackFunc=null, { redrawRows=false }={}) {
      
      if (saveState) {
        this.saveNodeState();
      }
      let list = await sandboxService.audit(this.$store.state.sandbox.value).then(response => {
        if (response.data.jobCase) {
          return response.data[response.data.jobCase];
        }
        return [];
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
      
      let data = [];
      for (let i = 0; i < list.length; i++) {
        data = this.extractRows(data, list[i]);
      }
      data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      
      this.rowData = data;
    }
    , checkExists(list, items, data) {
      for (const item of items) {
        if (!list.find(l => l.uuId === item.uuId)) {
          const found = data.filter(l => l.uuId === item.uuId);
          if (found.length !== 0) {
            found[0].errorMessage = 'sandbox.error.not_exists_in_live';
          }
        }
      }
    }
    , extractRows(data, item) {
      if (item.entitiesList) {
        for (const i of item.entitiesList) {
          i.treepath = item.treepath ? `${item.treepath}, ${i.uuId}` : `${i.entity}, ${i.uuId}`;
          if (!i.entity && item.entity === 'CATEGORY') {
            i.entity = item.name;
          }
          
          if (i.status !== 'ORIGINAL') {
            i.published = this.$t('sandbox.publish_status.not_published');
          }
          
          // keep a reference to the items to pass to the detail modal
          this.items[i.uuId] = i;
          
          if (i.uuId in migrateStatus) {
            i.published = migrateStatus[i.uuId] === 'OK' || 
                          migrateStatus[i.uuId] === 'Created' || 
                          migrateStatus[i.uuId] === 'Nothing_to_do' ||
                          migrateStatus[i.uuId] === 'Already_have_edge' ||
                          migrateStatus[i.uuId] === 'Updated' ? this.$t('sandbox.publish_status.published') : migrateStatus[i.uuId];
                          
            if (this.isError(i.published)) {
              i.published = this.$t('sandbox.publish_status.failed');
            }
          }
          
          if ((i.status !== 'ORIGINAL' || (i.entitiesList && i.entitiesList.length > 0)) && 
              !data.find(f => f.uuId === i.uuId && f.treepath === i.treepath)) {
            if (i.entity !== 'TASK' && 
                !data.find(f => f.uuId === i.entity)) {
              data.push({ uuId: i.entity, name: this.$t(`entityTypePlural.${i.entity}`), treepath: i.entity, editable: false });
            }
            if (i.entity === 'TASK') {
              // use the full path
              i.name = i.path.replace(/\n/g, ' / ');//eslint-disable-line
            }
            data.push(i);
            data = this.extractRows(data, i);
          }
        }
      }
      
      /*if (item.dependencies) {
        for (const j of item.dependencies) {
          j.treepath = `${item.treepath}, ${j.uuId}`;
          if (!data.find(f => f.uuId === j.uuId && f.treepath === j.treepath)) {
            data.push(j);
          }
        }
      }*/
      
      return data;
    }
    , isPropertyCompatible(src, tgt) {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(this.dataGroup)
      for(const key of keys) {
        if (this.dataGroup[key].includes(src) && this.dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
    , getExportDataPropertyHandler() {
      const formatArray = (params) => {
        if (Array.isArray(params.value)) {
          return params.value.map(i => i.name).join(',')
        }
        return ''
      }

      return {
        locations: (params) => {
          return formatArray(params)
        }
      }
    }
    , getValueChangedHandler() {
      return {
      }
    }
    , getParentCompanyColor(params) {
      if (params.data &&
          params.data.companyColor &&
          params.data.companyColor.length > 0 &&
          params.data.companyColor[0] !== '') {
        return params.data.companyColor[0];   
      }
      else if (params.node && params.node.parent) {
        return this.getParentCompanyColor(params.node.parent);
      }
      return null;
    }
    , updatebtnDisableStatus() {
      const hasCompanyRow = 
        this.entitySelection.filter(i => {
          if (this.gridApi == null) {
            return true
          }
          const rowNode = this.gridApi.getRowNode(i.uuId)
          if (rowNode == null) {
            return true
          }
          return typeof rowNode.data.type !== 'undefined'
        }).length !== 0
      this.disableDelete = this.entitySelection.length < 1 || hasCompanyRow
      this.disableDuplicate = this.entitySelection.length != 1 || hasCompanyRow 
    }
    , pruneTree(data, searchFilter) {
      if (searchFilter === '') {
        return data;
      }
      
      var regex = new RegExp(searchFilter, "i");
      for (var i = data.length - 1; i >= 0; i--) {
        // copy tags
        if (this.entityMap && data[i].uuId in this.entityMap) {
          data[i].tag = this.entityMap[data[i].uuId].tag;
          data[i].identifier = this.entityMap[data[i].uuId].tag;
        }

        const deptMatch = (
          (typeof data[i].name !== 'undefined' && data[i].name.match(regex) !== null) ||
          (typeof data[i].identifier === 'string' && data[i].identifier.match(regex) !== null) ||
          (typeof data[i].tag === 'string' && data[i].tag.match(regex) !== null)
        );

        if (typeof data[i].departmentList !== 'undefined') {
          data[i].departmentList = this.pruneTree(data[i].departmentList, searchFilter);
        }
        if (typeof data[i].staffList !== 'undefined' && !deptMatch) {
          delete data[i].staffList;
        }
        if (typeof data[i].companyList !== 'undefined' && !deptMatch) {
          data[i].companyList = this.pruneTree(data[i].companyList, searchFilter);
        }

        if (
          (typeof data[i].departmentList === 'undefined' || data[i].departmentList.length === 0) &&
          (typeof data[i].staffList === 'undefined' || data[i].staffList.length === 0) &&
          (typeof data[i].companyList === 'undefined' || data[i].companyList.length === 0)) {
            
          let matched = false;
          if ((typeof data[i].name !== 'undefined' && data[i].name.match(regex) !== null) ||
              (typeof data[i].firstName !== 'undefined' && data[i].firstName.match(regex) !== null) || 
              (typeof data[i].lastName !== 'undefined' && data[i].lastName.match(regex) !== null)) {
            matched = true;
          } 
          
          if (!matched && Array.isArray(this.customFields) && this.customFields.length > 0) {
            for (const c of this.customFields) {
              if ((c.type == 'String' || c.type == 'Enum<String>')) {
                if (data[i][c.name] != null && data[i][c.name].match(regex) !== null) {
                  matched = true;
                  break;
                }
              }
            }
          }

          if (!matched) {
            data.splice(i, 1);
          }
        }
      }
      return data;
    }
    
    , extractRowsFromData(treepath, data, listStaff) {
      const rows = [];
      if (data !== null) {
        // sort the data by name
        data.sort(sortFunc);
        
        if (this.exclude !== null && typeof data !== 'undefined') {
          for (var i = 0; i < data.length; i++) {
            if (data[i].uuId === this.exclude) {
              data.splice(i, 1); // do not list the department itself or its children
              break;
            }
          }
        }
        
        for (let i = 0; i < data.length; i++) {
          // copy tags
          if (this.entityMap && data[i].uuId in this.entityMap) {
            data[i].tag = this.entityMap[data[i].uuId].tag;
            data[i].identifier = this.entityMap[data[i].uuId].identifier;
            // data[i].companyColor = self.entityMap[data[i].uuId].companyColor;
          }
          
          const deptPath = treepath === '' ? data[i].uuId : `${treepath}, ${data[i].uuId}`;
          data[i].treepath = deptPath;
          rows.push(data[i]);
          if (listStaff && typeof data[i].staffList !== 'undefined') {
            for (let j = 0; j < data[i].staffList.length; j++) {
              const staffPath = `${deptPath}, ${data[i].staffList[j].uuId}`;
              rows.push({
                name: data[i].staffList[j].lastName ? data[i].staffList[j].firstName + " " + data[i].staffList[j].lastName : data[i].staffList[j].firstName,
                uuId: data[i].staffList[j].uuId,
                position: data[i].staffList[j].position,
                identifier: data[i].staffList[j].identifier,
                status: data[i].staffList[j].status,
                treepath: staffPath,
                staff: true,
                genericStaff: data[i].staffList[j].genericStaff,
                staffFirstName: data[i].staffList[j].firstName, //use different property name to avoid a conflict in detailLinkLabel()
                staffLastName: data[i].staffList[j].lastName, //use different property name to avoid a conflict in detailLinkLabel()
              });
            }
          }
          
          if (typeof data[i].departmentList !== 'undefined') {
            rows.unshift(...this.extractRowsFromData(deptPath, data[i].departmentList, listStaff));
          }
          
          if (typeof data[i].companyList !== 'undefined') {
            rows.unshift(...this.extractRowsFromData(deptPath, data[i].companyList, listStaff));
          }
        }
      }
      return rows;
    }

    , populateCompanyWithExtraData(companies, companyMap) {
      if (!Array.isArray(companies)) {
        return
      }

      for (let i = 0, len = companies.length; i < len; i++) {
        const company = companies[i]
        const found = companyMap[company.uuId]
        if (found != null) {
          company.tag = found.tag
        }
        
        if (company.companyList != null) {
          this.populateCompanyWithExtraData(company.companyList, companyMap)
        }
      }
    }
    , getPropertyDeleteHandler() {
      return {

      }
    }
    , processNodes() {
      if (this.gridApi) {
        let chosenPath = null;
        this.gridApi.forEachNode((node) => {
          if (this.company === null &&
              typeof node.data.type !== 'undefined' &&
              node.data.uuId !== this.$store.state.company.uuId) {
            node.setExpanded(false);
          } else if (node.data != null && node.data.uuId == this.$store.state.company.uuId && node.data.treepath != null) {
            chosenPath = node.data.treepath.split(', ');
          }
        });

        //Expand the parent node of matched company node
        if (chosenPath != null) {
          chosenPath.pop();
          while(chosenPath.length > 0) {
            const found = this.gridApi.getRowNode(chosenPath);
            if (found) {
              found.setExpanded(true);
            }
            chosenPath.pop();
          }
        }
      }
    }
    , prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('sandbox.grid.no_changes');
    }

    , showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridApi != null) {
        this.gridApi.hideOverlay()
        setTimeout(() => {
          this.gridApi.showNoRowsOverlay()
        })
      }
    }

    , onCellEditingStarted(/** event **/) {
      this.isCellEditing = true;
    }
    , onCellEditingStopped(/** event **/) {
      this.isCellEditing = false;
    }
    , filterCompany(treeNode, filterIds) {
      if (filterIds.length == 0) {
        this.trimPropertiesForUnrelatedCompany(treeNode);
        if (Array.isArray(treeNode.companyList) && treeNode.companyList.length > 0) {
          const toRemoveList = [];
          for (const t of treeNode.companyList) {
            this.filterCompany(t, filterIds);
            if (t.keep != true) {
              toRemoveList.push(t.uuId);
            } else {
              treeNode.keep = true;
              delete t.keep;
            }
          }
          while(toRemoveList.length > 0) {
            const toRemovedId = toRemoveList.pop();
            const idx = treeNode.companyList.findIndex(i => i.uuId == toRemovedId);
            treeNode.companyList.splice(idx, 1);
          }
        }
        return;
      }
      
      if (treeNode.uuId == null) {
        this.trimPropertiesForUnrelatedCompany(treeNode);
        if (Array.isArray(treeNode.companyList) && treeNode.companyList.length > 0) {
          const toRemoveList = [];
          for (const t of treeNode.companyList) {
            this.filterCompany(t, filterIds);
            if (t.keep != true) {
              toRemoveList.push(t.uuId);
            } else {
              treeNode.keep = true;
              delete t.keep;
            }
          }
          while(toRemoveList.length > 0) {
            const toRemovedId = toRemoveList.pop();
            const idx = treeNode.companyList.findIndex(i => i.uuId == toRemovedId);
            treeNode.companyList.splice(idx, 1);
          }
        }
        return;
      }
      
      const foundIdx = filterIds.findIndex(i => i == treeNode.uuId || (treeNode.companyPath != null && treeNode.companyPath.includes(i)))
      if (foundIdx > -1) {
        treeNode.keep = true;
        filterIds.splice(foundIdx, 1);
        return;
      }
      
      this.trimPropertiesForUnrelatedCompany(treeNode);
      if (Array.isArray(treeNode.companyList) && treeNode.companyList.length > 0) {
        const toRemoveList = [];
        for (const t of treeNode.companyList) {
          this.filterCompany(t, filterIds);
          if (t.keep != true) {
            toRemoveList.push(t.uuId);
          } else {
            treeNode.keep = true;
            delete t.keep;
          }
        }
        while(toRemoveList.length > 0) {
          const toRemovedId = toRemoveList.pop();
          const idx = treeNode.companyList.findIndex(i => i.uuId == toRemovedId);
          treeNode.companyList.splice(idx, 1);
        }
      }
      else {
        // keep dangling departments
        treeNode.keep = true;
      }
    }
    , trimPropertiesForUnrelatedCompany(treeNode) {
      if (Array.isArray(treeNode.departmentList) && treeNode.departmentList.length > 0) {
        treeNode.departmentList.splice(0, treeNode.departmentList.length);
      }
      if (Array.isArray(treeNode.staffList) && treeNode.staffList.length > 0) {
        treeNode.staffList.splice(0, treeNode.staffList.length);
      }
    }
    , onFilterOver() {
      this.$refs.filter.visible = true;
    }
    , onFilterLeave() {
      this.$refs.filter.visible = false;
    }
    , onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    }
    , onFilterSubmit() {
      this.filterTextFocus = false;
      this.$refs.filter.visible = false;
      this.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.
      
      this.applyFilter(this.filterText);
    }
    , onFilterClear() {
      this.filterText = '';
      this.applyFilter(this.filterText);
    }
    , onBadgeFilterModified(filter) {
      this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      const removedFilterFields = this.badgeFilters.filter(i => filter.find(j => j.field == i.field) == null).map(i => i.field);
      this.badgeFilters = filter;
      
      for (const f of filter) {
        if (Object.hasOwn(this.badgeFilterFieldValues, f.field)) {
          if (Array.isArray(f.value) && f.value.length > 0) {
            this.badgeFilterFieldValues[f.field].forEach(i => {
              i.checked = f.value.find(j => {
                if (typeof i.text === 'string') {
                  return j.text.localeCompare(i.text, undefined, { sensitivity: 'base' }) == 0
                }
                return j.text == i.text
              }) != null
            });
          } else {
            this.badgeFilterFieldValues[f.field].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
          }
        }
      }
      //reset checked state for the previously selected but now removed filter
      if (removedFilterFields.length > 0) {
        for (const f of removedFilterFields) {
          if (Object.hasOwn(this.badgeFilterFieldValues, f)) {
            this.badgeFilterFieldValues[f].forEach(i => {
              if (i.checked) {
                i.checked = false;
              }
            });
           
          }
        }
      }
      this.badgeFilterFieldValues = JSON.parse(JSON.stringify(this.badgeFilterFieldValues)); //Force triggering vue reactivity
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] = filter;
      this.updateViewProfile()
      this.changeFilter();
    }
    , onBadgeFilterDropdownHide(bvEvent) {
      if (this.badgeFilterFocus || this.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    }
    , onBadgeFilterEnter() {
      this.$refs.badgeFilter.visible = true;
    }
    , onBadgeFilterOver(evt) {
      if (this.$refs.badgeFilter?.$el.id != null && evt.target.closest('.dropdown-toggle') != null && this.badgeFilterModalOpened != 'open' && this.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs.badgeFilter?.$el.id)) {
          this.badgeFilterFocus = false; 
        }
      }
    }
    , onBadgeFilterLeave() {
      if (!this.badgeFilterFocus) {
        this.$refs.badgeFilter.visible = false;
      }
    }
    , onBadgeFilterModalOpened() {
      this.badgeFilterModalOpened = 'open';
      this.badgeFilterFocus = true;
    }
    , onBadgeFilterModalClosed() {
      this.badgeFilterModalOpened = 'signaled-close';
      this.badgeFilterFieldValues = {};
    }
    , toggleBadgeFilterFocus(evt) {
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && this.$refs.badgeFilter?.$el?.id != null && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }
    }
    , handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && this.badgeFilterFocus) {
        this.badgeFilterFocus = false;
        this.badgeFilterModalOpened = 'close';
        this.closePriorityNavDropdown = true;
      }
    }
    , onPriorityNavMouseOverOrTouchEnd(evt) {
      if ((this.$refs.badgeFilter?.$el.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      }
    }
    , toggleCurrentColumnVisibility(toHide) {
      const columnDefs = this.gridApi.getColumnDefs();
      for (const cDef of columnDefs) {
        if (cDef.colId == 'rowSelector') {
          cDef.hide = false; //rowSelector is always visible.
          continue;
        }
        if (cDef.colId == 'path') {
          cDef.hide = true;
          continue;
        }
        cDef.hide = toHide;
      }
      this.gridApi.setGridOption('columnDefs', columnDefs);
    }
    , showAllColumns() {
      this.toggleCurrentColumnVisibility(false);
    }
    , showNoColumns() {
      this.toggleCurrentColumnVisibility(true);
    }
    , savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        name: this.saveName, 
        type: `${this.entity}_admin_selector`, 
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        columns: this.profileSettings[this.profileKeySelector],
        coloring: this.coloring,
        filterText: cloneDeep(this.filterText),
        badgeFilters: JSON.parse(JSON.stringify(this.badgeFilters))
      };
      this.promptSaveShow = true;
    }
    , async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0][this.profileKeySelector] = profile.columns;
            profileData[0][this.profileKeyColoring] = profile.coloring;
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] = profile.filterText;
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] = profile.badgeFilters;
      
            // save the view name in the profile
            profileData[0][`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = profile.name;
            
            await service.update(profileData, user)
          }
        }
      }
    }
    , confirmSaveOk({ /**name,*/ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.views.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, viewProfileService);
      
      if (this.saveIndex !== -1) {
        this.views.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (!sharing) {
        // save the view name in the profile
        this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = this.viewName = profile.name;
        this.updateViewProfile({ clearViewName: false });
      }
    }
    , addViews(views) {
      if (typeof views === 'undefined') {
        return;
      }
      
      for (const view of views) {
        // if not in the list, add it
        if (view.type === `${this.entity}_admin_selector` &&
            this.views.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.views.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
          else {
            let params = new URL(document.location.toString()).searchParams;
            if (params.has("view")) {
              let uuId = params.get("view");
              if (view.uuId === uuId) {
                this.loadViewSettings(view);
              }
            }
          }
        }
      }
      
      this.views.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    }
    , editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    }
    , loadViewSettings(view) {
      //A flag is set to prevent the viewName being removed accidentally from (layout) profile in the onColumnVisible() event.
      //Event:onColumnVisible() is only fired when the previous columns and newly loaded columns are different.
      //Find out if they are different and set flag accordingly.
      let columnsMatched = true;
      let layoutCols = this.profileSettings[this.profileKeySelector];
      if (layoutCols.length > view.columns.length) {
        for (const col of layoutCols) {
          if (view.columns.find(i => i.colId == col.colId) == null) {
            columnsMatched = false;
            break;
          }
        }
      } else {
        for (const col of view.columns) {
          if (layoutCols.find(i => i.colId == col.colId) == null) {
            columnsMatched = false;
            break;
          }
        }
      }
      
      if (!columnsMatched) {
        this.skipViewNameReset = true;
      } else {
        this.skipViewNameReset = false;
      }

      this.profileSettings[this.profileKeySelector] = JSON.parse(JSON.stringify(view.columns));
      this.loadColumnSettings(this, this.profileSettings[this.profileKeySelector])

      const coloringChanged = JSON.stringify(view.coloring) !== JSON.stringify(this.coloring)
      const colorMenuOptions = {
        none: true
        , company: false
      }
      for (const optName in colorMenuOptions) {
        this.$set(this.coloring, optName, view.coloring? view.coloring[optName] : colorMenuOptions[optName])
      }
      this.profileSettings[this.profileKeyColoring] = this.coloring;
      
      this.filterText = typeof view.filterText !== 'undefined' ? view.filterText : '';
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_search`] = this.filterText;
      
      this.badgeFilters = Array.isArray(view.badgeFilters)? JSON.parse(JSON.stringify(view.badgeFilters)) : [];
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_badgeFilters`] = JSON.parse(JSON.stringify(this.badgeFilters));
      
      let params = new URL(document.location.toString()).searchParams;
      if (!params.has("view")) {
        this.changeFilter();
      }
      
      // save the view name in the profile
      this.profileSettings[`${this.formattedEntity}${this.nonAdmin? '' : '_admin'}_selector_view`] = this.viewName = view.name;
      
      // Save the new layout after applying it
      this.updateViewProfile({ clearViewName: false });

      if (coloringChanged) {
        this.gridApi.redrawRows();
      }
    }
    , async loadUserProfile() {
      const self = this;
      const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , async createSandboxProfile() {
      await sandboxProfileService.create([this.sandboxProfile],
                        this.$store.state.sandbox.value,
                        localStorage.companyId).then((response) => {  
        const data = response.data[response.data.jobCase]
        this.sandboxProfile.uuId = data[0].uuId
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , async loadSandboxProfile() {
      if (this.$store.state.sandbox.value === null) {
        return;
      }
      
      const self = this;
      const sandboxProfile = await sandboxProfileService.list(this.$store.state.sandbox.value, localStorage.companyId).then((value) => {
        return value.data[value.data.jobCase];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      if (sandboxProfile.length === 0) {
        await this.createSandboxProfile();
        this.sandboxProfile.migrateStatus = migrateStatus;
        this.updateSandboxProfile();
      }
      else {
        this.sandboxProfile = sandboxProfile[0];
        migrateStatus = this.sandboxProfile.migrateStatus;
      }
    }
    , async updateSandboxProfile() {
      const self = this;
      sandboxProfileService.update([this.sandboxProfile], this.$store.state.sandbox.value, localStorage.companyId).then((value) => {
        
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , copyColumnSettings(name , profile) {
      const columns = profile.columns;
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: `${this.entity}_admin_selector`,
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        columns: columns,
        filterText: cloneDeep(profile.filterText),
        badgeFilters: cloneDeep(profile.badgeFilters),
        coloring: cloneDeep(profile.coloring)
      };
      this.saveIndex = -1;
      this.promptSaveShow = true;
    }
    , shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    }
    , updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: this.saveName, 
        uuId: profile.uuId, 
        type: `${this.entity}_admin_selector`, 
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        editingPermissions: cloneDeep(profile.editingPermissions),
        columns: this.profileSettings[this.profileKeySelector],
        coloring: this.coloring,
        filterText: cloneDeep(this.filterText),
        badgeFilters: cloneDeep(this.badgeFilters)
      };
      this.saveIndex = index;
      this.promptSaveShow = true;
    }
    , removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    }
    , confirmDeleteViewOk() {
      const toRemove = this.views.splice(this.deleteViewIndex, 1);
      viewProfileService.remove([{ uuId: toRemove[0].uuId }], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , confirmPublishedOk() {
      this.ignorePublished = true;
      this.publish();
    }
    , onInfoOver(index) {
      profileService.nodeList(this.views[index].uuId).then((response) => {
        this.views[index].owner = response.data.resultList.filter(v => this.views[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    }
    , onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    }
    , ok(event) {
      const details = this.gridApi.getSelectedNodes().filter(f => this.Checkable(f)).map(i => ({ 
        uuId: i.data.uuId
      }));

      const ids = details.map(i => i.uuId);
      this.$emit('ok', { ids, details: details, preselected: this.preselected });
    }
    , isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    }
    , selectAll() {
    
    }
    , detailLinkId(params) {
      return `${params.data.uuId}${params.data.treepath}`;
    }
    , Checkable(params) {
      return typeof params.data.editable !== 'undefined' ? params.data.editable : params.data.status !== 'ORIGINAL' && typeof params.data.uuId !== 'undefined' && (typeof params.data.entity === 'undefined' || ((-1 === params.data.entity.indexOf('-') || params.data.entity === 'PROJECT-TASK') && params.data.entity !== 'CATEGORY'))
    }
    , publish() {
        const data = [];
        const published = [];
        const links = {};
        this.gridApi.forEachNode( (node) => {
          if (!node.selected &&
              node.data.editable !== false) {
            data.push(node.data.uuId);
          }
          else if (node.data.entity) {
            links[node.data.entity] = true;
          }
          
          if (node.selected &&
              node.data.published === this.$t('sandbox.publish_status.published')) {
            published.push(node.data);
          }
        });
        
        if (!this.testMode &&
            !this.ignorePublished &&
            published.length > 0) {
          this.warnPublished = true;
          return;
        }
        
        this.disableOk = true;
        
        // reset the status
        this.status = null;
        const statusInterval = setInterval(()=> {
          this.getStatus();
        }, 5000);
        
        // sandbox is locked while migrating
        const canEdit = this.$store.state.sandbox.canEdit;
        this.$store.state.sandbox.canEdit = false;
        
        sandboxService.migrate(this.$store.state.sandbox.value, data, Object.keys(links), this.testMode).then(response => {
          clearInterval(statusInterval);
          this.status = 'migrated';
          if (response.status === 207) {
            this.resetAlert({ msg: this.$t('sandbox.publish_status.published_partial'), alertState: alertStateEnum.WARNING })
            this.handleError(response);
          }
          else {
            this.resetAlert({ msg: this.testMode ? this.$t('sandbox.publish_status.test_published') : this.$t('sandbox.publish_status.published') })
          }
          this.parseFeedbackList(response.data[response.data.jobCase]);
          this.disableOk = false;
          this.$store.state.sandbox.canEdit = canEdit;
        })
        .catch(e => {
          this.disableOk = false;
          if (e.request.statusCode !== 408) { // don't report error on timeout
            clearInterval(statusInterval);
            this.handleError(e.response);
            console.log(e); //eslint-disable-line
          }
        });
        
    }
    , getErrorItem(id) {
      let errorItem = null;
      this.gridApi.forEachNode( (node) => {
        if (node.data &&
            node.data.uuId === id) {
          errorItem = node;
        }
      });
      return errorItem;
    }
    , parseFeedbackList(list) {
      const redrawNodes = [];
      for (const item of list) {
        const note = item.note;
        if (note) {
          const match = note.match(UUID_MATCH);
          if (match && match.length === 2) {
            const uuId = match[1];
            // populate the status of the item using the clue
            if (this.testMode) {
              migrateStatusTest[uuId] = item.clue;
            }
            else {
              migrateStatus[uuId] = item.clue;
            }
            
            let rowNode = null
            this.gridApi.forEachNode( (node) => {
              if (node.data &&
                  node.data.uuId === uuId) {
                rowNode = node;
              }            
            });
            if (rowNode) {
              if (this.testMode) {
                rowNode.data.test_published = item.clue === 'OK' || 
                              item.clue === 'Created' || 
                              item.clue === 'Nothing_to_do' ||
                              item.clue === 'Already_have_edge' ||
                              item.clue === 'Updated' ? this.$t('sandbox.publish_status.published') : item.clue;
                                        
                if (this.isError(rowNode.data.test_published)) {
                  rowNode.data.test_published = this.$t('sandbox.publish_status.failed');
                }
              }
              else {
                rowNode.data.published = item.clue === 'OK' || 
                              item.clue === 'Created' || 
                              item.clue === 'Nothing_to_do' ||
                              item.clue === 'Already_have_edge' ||
                              item.clue === 'Updated' ? this.$t('sandbox.publish_status.published') : item.clue;
                                        
                if (this.isError(rowNode.data.published)) {
                  rowNode.data.published = this.$t('sandbox.publish_status.failed');
                }
              }
              redrawNodes.push(rowNode);
            }
          }
        }
      }
      
      if (redrawNodes.length > 0) {
        this.gridApi.redrawRows({rowNodes: redrawNodes});
      }
      
      if (!this.testMode) {
        this.sandboxProfile.migrateStatus = migrateStatus;
        if (this.sandboxProfile.uuId) {
          this.updateSandboxProfile();
        }
      }
    }
    , isError(value) {
      return value === 'Unknown_target' ||
                value === 'Not_unique_key' ||
                value === 'Not_found' ||
                value === "Unknown_parent" ||
                value === "Unknown_holder" ||
                value === "Unknown_relation" ||
                value === "Unknown_property" ||
                value === "Read_only_property" ||
                value === "String_limit_exceeded" ||
                value === "Number_limit_exceeded" ||
                value === "Number_limit_under" ||
                value === "Date_limit_exceeded" ||
                value === "Date_limit_under" ||
                value === "Invalid_value" ||
                value === "Unknown_relation" ||
                value === "Invalid_relation" ||
                value === "Validation_rule";
    }
    , handleError(response) {
      this.errorJob = null;
      const feedbackList = response.data.jobCase ? response.data[response.data.jobCase] : []
      if (response.data.jobClue) {
        if (response.data.jobClue.clue === 'Conflict') {
          this.errorJob = this.$t(`sandbox.error.conflict`);
        }
        else if (response.data.jobClue.clue === 'Unknown_relation') {
          let item1 = this.getErrorItem(response.data.jobClue.args[0]);
          let item2 = this.getErrorItem(response.data.jobClue.args[1]);
          this.errorJob = this.$t(`sandbox.error.unknown_relation_arg2`, [item1.data.name, item2]);
        }
        else if (response.data.jobClue.clue === 'Unknown_identifier') {
          let item1 = this.getErrorItem(response.data.jobClue.args[0]);
          let item2 = this.getErrorItem(response.data.jobClue.args[1]);
          const entity = item1.data.entity !== 'PROJECT_TEMPLATE' ? item1.data.entity : 'TASK_TEMPLATE';
          this.errorJob = this.$t(`sandbox.error.unknown_identifier_arg2`, [this.$t(`entityType.${entity}`), item1.data.name]);
        }
        else {
          const error = feedbackList[feedbackList.length - 1];
          const note = error.note;
          if (note) {
            const match = note.match(UUID_MATCH);
            if (match && match.length === 2) {
              const uuId = match[1];
              let rowNode = null
              this.gridApi.forEachNode( (node) => {
                if (node.data &&
                    node.data.uuId === uuId) {
                  rowNode = node;
                }            
              });
              if (rowNode) {
                this.errorJob = this.$t('sandbox.error.failed_to_publish', [this.$t(`sandbox.status.${rowNode.data.status.toLowerCase()}`), this.$t(`staff.group.${rowNode.data.entity.toLowerCase()}`), rowNode.data.name]);
                rowNode.data.published = this.$t('sandbox.publish_status.failed');
                this.gridApi.redrawRows({rowNodes: [rowNode]});
              }
              
              if (this.testMode) {
                migrateStatusTest[uuId] = error.clue;
              }
              else {
                migrateStatus[uuId] = error.clue;
              }
            }
          }
        }
      }
      else {
        const error = feedbackList[feedbackList.length - 1];
        this.showItemError(error);
      }
      
      if (this.show) {
        this.showErrorJob = true;
      }
    }
    , showItemError(error) {
      this.errorJob = this.$t(`sandbox.error.${error.clue}`);
      let errorItem = this.getErrorItem(error.args[0]);
      
      if (errorItem && errorItem.data) {
        if (errorItem.data.entity) {
          this.errorJob = this.$t(`sandbox.error.entity_${error.clue}`, [errorItem.data.entity, errorItem.data.name]);
        }
        else {
          this.errorJob = this.$t(`sandbox.error.${error.clue}`, [errorItem.data.name]);
        }
      }
    }
    , detailLinkTag(params) {
      if (typeof params.data.editable !== 'undefined') {
        return null;
      }
      
      if (params.data && params.data.properties && 
          params.data.properties.genericStaff) {
        return this.$t('staff.group.generic');
      }
      else if (params.data && params.data.entity) {
        return this.$t(`staff.group.${params.data.entity.toLowerCase()}`);
      }
      
      return this.$t('staff.group.staff');
    }
    , detailLinkTagClass(params) {
      if (typeof params.data.editable !== 'undefined') {
        return null;
      }
      
      let data = params.data;
      
      if (data && data.entity) {
        if (data.entity.toLowerCase() === 'company') {
          return 'tag-purple';
        }
        else if (data.entity.toLowerCase() === 'location') {
          return 'tag-teal';
        }
        else if (data.entity.toLowerCase() === 'stage') {
          return 'tag-stage';
        }
        else if (data.entity.toLowerCase() === 'project' ||
            data.entity.toLowerCase() === 'project_template') {
          return 'tag-project';
        }
        else if (data.entity.toLowerCase() === 'department') {
          return 'tag-indigo';
        }
        else if (data.entity.toLowerCase() === 'skill') {
          return 'tag-red';
        }
        else if (data.entity.toLowerCase() === 'task') {
          return 'tag-yellow';
        }
        else if (data.entity.toLowerCase() === 'activity') {
          return 'tag-activity';
        }
        else if (data.entity.toLowerCase() === 'booking') {
          return 'tag-booking';
        }
        else if (data.entity.toLowerCase() === 'staff' && data.properties.genericStaff) {
          return 'tag-staff-generic';
        }
        else if (data.entity.toLowerCase() === 'calendar') {
          return 'tag-vacation';
        }
        else if (data.entity.toLowerCase() === 'resource') {
          return 'tag-resource';
        }
        else if (data.entity.toLowerCase() === 'customer') {
          return 'tag-customer';
        }
        else if (data.entity.toLowerCase() === 'note') {
          return 'tag-note';
        }
        else if (data.entity.toLowerCase() === 'rebate') {
          return 'tag-rebate';
        }
        else if (data.entity.toLowerCase() === 'tag') {
          return 'tag-tag';
        }
      }
      
      return 'tag-pumpkin';
    }
    , isEditable(params) {
      return false;
    }
  }
}


</script>

<style lang="scss">
.spreadsheet .ag-row-selected::before,
.spreadsheet .ag-row-selected.ag-row-hover::before {
  background-color: transparent;
}

.spreadsheet .ag-cell-range-selected.ag-cell-focus, .spreadsheet .ag-body-viewport.ag-has-focus .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: var(--ag-range-selection-background-color);
  border: 2px solid  !important;
  border-color: var(--ag-range-selection-border-color) !important; 
  border-style: var(--ag-range-selection-border-style) !important;
}
</style>