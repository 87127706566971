<template>
  <div v-if="isErrorLink()">
    <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="mr-1 mb-2" style="vertical-align: bottom;color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
    <a :id="`ERROR_DETAIL_LINK_${params.eGridCell.getAttribute('comp-id')}`" href="#" @click.stop.prevent="invokeParentMethod">{{ label }}</a>
  </div>
  <div v-else-if="label === $t('sandbox.publish_status.published')">
    <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="mr-1 mb-2" style="vertical-align: bottom;color: green;font-size: 1.125em;" :icon="['fas', 'circle-check']"/>
    <span>{{ label }}</span>
  </div>
  <span v-else>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { invertColor } from '@/helpers';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'ErrorDetailLinkCellRenderer',
  data() {
    return {}
  },
  beforeMount() {

  },
  computed: {
    tooltip() {
      const tooltipFunc = this.params.context.componentParent.detailLinkTooltip;
      if (tooltipFunc) {
        return tooltipFunc(this.params);
      }
      return null;
    },
    label() {
      return this.params.value;
    }
  },
  methods: {
    invokeParentMethod(e) {
      if (this.params.showErrorDetailFunc) {
        this.params.showErrorDetailFunc(this.params);
        return;
      }
    },
    isErrorLink() {
      return this.params.isErrorFunc(this.params);
    },
    refresh(params) {
      this.params = params;
    },
  }
});
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 3px;
  vertical-align: center;
  display: inline-block;
  color: var(--white);
  font-size: 0.8em;
  padding: 0 5px 0 5px;
  height: 15px;
  line-height: 15px;
  margin-right: 3px;
}

.tag-purple {
  background-color: var(--deep-purple-400);
}

.tag-indigo {
  background-color: var(--blue-900);
}

.tag-red {
  background-color: var(--red-200);
}

.tag-light-blue {
  background-color: var(--light-blue);
}

.tag-white {
  background-color: var(--green-400);
  color: var(--white);
}

.tag-blue {
  background-color: var(--blue);
}

.tag-teal {
  background-color: var(--teal);
}

.tag-pumpkin {
  background-color: var(--pumpkin);
}

.tag-yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.image {
  object-fit: cover;
  width: 16px;
  height: 16px;
  border: 2px solid var(--banner-avatar-border);
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 1;
  vertical-align: baseline;
  margin-right: 3px;
  margin-bottom: 2px;
}

</style>