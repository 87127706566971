

<template>
  <div class="d-flex">
    <div class="d-flex select-button">
     <div ref="menuButton" class="customHeaderMenuButton" @click="onSelectToggle($event)">
       <font-awesome-icon class="selection-icon" :icon="selectedAll ? ['far', 'square-check'] : selectedSome ? ['far', 'square-minus'] : ['far','square']"/>
     </div>
     <div v-if="params.enableMenu" ref="menuButton" class="select-menu ml-1 customHeaderMenuButton" @click="onMenuClicked($event)">
       <font-awesome-icon class="selection-icon" :icon="['far','caret-down']"/>
     </div>
   </div>
   <span class="ml-1 display-name">{{ params.displayName }}</span>
  </div>
</template>

<script>
import Vue from 'vue';
import { fieldOptions, initFieldOptions } from '@/selectOptions';

export default Vue.extend({
  name: 'SelectionHeaderComponent',
  data() {
    return {
      value: null,
      options: fieldOptions,
           ascSort: null,
           descSort: null,
           noSort: null,
           selectedAll: false,
           selectedSome: false,  
    }
  },
  beforeMount() {
    this.prepareData();
  },
  methods: {
    prepareData() {
      this.$nextTick(() => {
        const selectionStatus = this.getSelectionStatus();
        this.selectedAll = selectionStatus == 'all';
        this.selectedSome = selectionStatus == 'some';
      });
    },
    refresh(params) {
      this.params = params;
      this.prepareData();
    },
    getSelectionStatus() {
      const api = this.params.api;
      
      const selectedRows = api.getSelectedRows();

      const rowNode  = api.getDisplayedRowAtIndex(0);
      const totalCount = rowNode && rowNode.id == 'ROOT'? api.rowModel.rowsToDisplay.length-1 : api.rowModel.rowsToDisplay.length;
      if (selectedRows.length == 0) {
        return 'none';
      } else if ( selectedRows.length == totalCount) {
        return 'all';
      } else {
        return 'some';
      }
    },
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSelectToggle() {
      if (this.selectedAll) {
        const api = this.params.context.componentParent.gridApi;
        api.deselectAllFiltered();
        this.selectedAll = this.selectedSome = false;
      }
      else {
        const api = this.params.context.componentParent.gridApi;
        api.selectAllFiltered();
        this.selectedAll = true;
        this.selectedSome = false;
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.selection-icon {
  font-size: 18px;
  margin-top: 1px;
  color: var(--gray-500);
}

.select-menu {
  padding: 0 2px;
}

.select-button {
  padding: 3px;
  width: fit-content;
  border: 1px solid transparent;
}

.display-name {
  margin-top: 5px;
}

.select-button:focus,
.select-button:active,
.select-button:hover {
  background: var(--backdrop);
  border-radius: 3px;
  border: 1px solid var(--border-dark);
}
</style>
