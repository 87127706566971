var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isErrorLink()
    ? _c(
        "div",
        [
          _c("font-awesome-icon", {
            staticClass: "mr-1 mb-2",
            staticStyle: {
              "vertical-align": "bottom",
              color: "red",
              "font-size": "1.125em",
            },
            attrs: {
              id: `CELL_ERROR_${_vm.params.eGridCell.getAttribute("comp-id")}`,
              icon: ["fas", "triangle-exclamation"],
            },
          }),
          _c(
            "a",
            {
              attrs: {
                id: `ERROR_DETAIL_LINK_${_vm.params.eGridCell.getAttribute(
                  "comp-id"
                )}`,
                href: "#",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.invokeParentMethod.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.label))]
          ),
        ],
        1
      )
    : _vm.label === _vm.$t("sandbox.publish_status.published")
    ? _c(
        "div",
        [
          _c("font-awesome-icon", {
            staticClass: "mr-1 mb-2",
            staticStyle: {
              "vertical-align": "bottom",
              color: "green",
              "font-size": "1.125em",
            },
            attrs: {
              id: `CELL_ERROR_${_vm.params.eGridCell.getAttribute("comp-id")}`,
              icon: ["fas", "circle-check"],
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.label))]),
        ],
        1
      )
    : _c("span", [_vm._v(_vm._s(_vm.label))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }